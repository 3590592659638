import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import PageLayout from '../Infrastructure/PageLayout';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GetCashBalance } from './AdminService';
import AuthContext from '../../App/AuthContext';
import DisplayValue from '../Partials/DisplayValue';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles((theme) => ({
    header: {
        paddingBottom: theme.spacing(0.5),
    },
    root: {
        width: '100%',
    },
    summary: {
        "& .MuiGrid-item p": {
            fontSize: theme.typography.pxToRem(14),
        }
    },
    detail: {
        paddingTop: "0",
        paddingBottom: theme.spacing(0.5),
        "& .MuiGrid-item p": {
            fontSize: theme.typography.pxToRem(14),
        }
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    alignright: {
        textAlign: 'right',
    },
}));

function TreasuryBal(props) {
    const classes = useStyles();
    const authCtx = useContext(AuthContext);
    const [expanded, setExpanded] = useState(null);
    const [apiBalance, setApiBalance] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        const getBalanceList = () => {
            const handler = (res) => {
                setApiBalance(res);
            }
            if (!apiBalance)
                GetCashBalance(authCtx.auth.token, handler);
        }
        getBalanceList();
    }, [apiBalance, authCtx.auth.token]);

    const goTreasury = () => {
        props.history.push('/treasury');
    }

    return (
        apiBalance &&
            <PageLayout width="md">
                <Button
                    size="small"
                    color="secondary"
                    variant="text"
                    margin="none"
                    startIcon={<ChevronLeftIcon />}
                    onClick={goTreasury}
                >
                    Pokladna
            </Button>
            <div className={classes.root}>
                    {apiBalance.map(item => {
                        return (
                             <Accordion key={item.Year ? item.Year : -1} 
                                expanded={(item.Year ? item.Year : -1) === expanded} 
                                onChange={handleChange(item.Year ? item.Year : -1)}
                            >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} id={item.Year ? item.Year : -1}>
                                    <Grid container className={classes.summary}>
                                        <Grid item xs={4}><Typography></Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.alignright}>
                                            <Typography color={item.Year ? 'textSecondary' : 'secondary'}>
                                                {item.Year ? 'Příjem/Výdej' : 'Aktuální stav'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.alignright}>
                                            <Typography color={item.Year ? 'textSecondary' : 'secondary'}>
                                                Dluh
                                    </Typography>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <Typography color={item.Year ? 'initial' : 'secondary'}>
                                                {item.Year ? item.Year : 'Celkem'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.alignright}>
                                            <Typography>
                                                <DisplayValue value={item.Balance} type='currency' />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} className={classes.alignright}>
                                            <Typography>
                                                <DisplayValue value={item.Debt} type='currency' />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails className={classes.detail}>
                                    {
                                        item.Detail ? (
                                            <Grid container>
                                                <Grid container>
                                                <Grid item xs={6}>
                                                    <Typography color='textSecondary'>Typ platby</Typography>
                                                </Grid>
                                                <Grid item xs={3} className={classes.alignright}>
                                                    <Typography color='textSecondary'>Příjem/Výdej</Typography>
                                                </Grid>
                                                <Grid item xs={3} className={classes.alignright}>
                                                    <Typography color='textSecondary'>Dluh</Typography>
                                                </Grid>
                                                <Grid item xs={12} className={classes.alignright}>
                                                    <Divider className={classes.divider} />
                                                </Grid>
                                                </Grid>
                                                {item.Detail.map(det => {
                                                    return (
                                                    <Grid container key={det.PaymentTypeId}>
                                                         <Grid item xs={6}>
                                                            <Typography>
                                                                {det.PaymentType}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} className={classes.alignright}>
                                                            <Typography>
                                                                <DisplayValue value={det.Balance} type='currency' />
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={3} className={classes.alignright}>
                                                            <Typography>
                                                                <DisplayValue value={det.Debt} type='currency' />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    )
                                                })
                                                }
                                            </Grid>
                                        ) : (
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Typography>Bankovní spojení: 1391984012 kód banky 3030</Typography>
                                                    </Grid>
                                                </Grid>)
                                    }
                                </AccordionDetails>
                            </Accordion>
                         )
                    })
                    }
                </div>
            </PageLayout>
            
    );
}

export default TreasuryBal;