import React from 'react';
import { FormControl, FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';
import { Field } from 'formik';


export default function Checkbox(props) {
    const { name, label, color="primary", margin="normal", ...rest } = props;
   
    const convertToDefEventPara = (name, value) => (
        { target: { name, value } }
    )

    return (
        <Field name={name}>
        {({field}) => {
            return (
            <FormControl margin={margin} fullWidth>
                <FormControlLabel
                    control={
                        <MuiCheckbox 
                            name={name} 
                            color = {color} 
                            checked = {field.value} 
                            onChange = {(e) => {field.onChange(convertToDefEventPara(name, e.target.checked))}}
                            //indeterminate = {false}
                            //indeterminateIcon
                            //required=true
                        />
                    }
                    label={label} 
                    {...rest}
                />
            </FormControl>
            )
        }
        }
        </Field>
    )
}