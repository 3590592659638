import Input from './Input';
import NumberInput from './NumberInput'
/* import RadioGroup from './RadioGroup';*/
import DropDownList from './DropDownList';
import Checkbox from './Checkbox';
import CheckBoxGroup from './CheckBoxGroup';
import DatePicker from './DatePicker';
import DateTimePicker from './DateTimePicker';
import Button from './Button';
import Autocomplete from './Autocomplete'
/*import ActionButton from './ActionButton';
 */
const Controls = {
    Input,
    NumberInput,
    /* RadioGroup,
    Select,*/
    DropDownList,
    Checkbox,
    DatePicker, 
    DateTimePicker, 
    Button,
    /*ActionButton */
    CheckBoxGroup,
    Autocomplete,
}

export default Controls;