import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { IconButton, withWidth } from '@material-ui/core';
import { FormHeader } from '../Infrastructure/PageElements';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.some(x => x.profileId === value));
}

function sortList(a) {
  return a.sort(function(a, b) { return a.jerseyNumber < b.jerseyNumber ? -1 : 1 });
}

function LeagueList(props) {
  const { teamList, availList, setTeamList = null } = props;
  const { width } = props;

  const [ tList, setTList] = useState(null);
  const [ aList, setAList] = useState(null);
  
    const [checked, setChecked] = useState([]);

  const leftChecked = intersection(checked, tList);
  const rightChecked = intersection(checked, aList);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setAList(sortList(aList.concat(tList.filter((value) => leftChecked.some(x => value.profileId === x)))));
    var tl = sortList(tList.filter((value) => !leftChecked.some(x => value.profileId === x)));
    setTList(tl);
    if (setTeamList) setTeamList(tl);
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    var tl = sortList(tList.concat(aList.filter((value) => rightChecked.some(x => value.profileId === x))));
    setTList(tl);
    if (setTeamList) setTeamList(tl);
    setAList(sortList(aList.filter((value) => !rightChecked.some(x => value.profileId === x))));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    setTList(teamList);
    setAList(availList)
  }, [teamList, availList]);

  const list = (items) => (
    
      <List dense disablePadding component="div" role="list">
        {items.map((value) => {
          const labelId = `teamList-${value.profileId}`;

          return (
            <ListItem key={value.profileId} role="listitem" button={true} onClick={handleToggle(value.profileId)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value.profileId) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.jerseyNumber ?? '0'} - ${value.playerName}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
  )

  return (
    tList && aList &&
    <Grid container spacing={1} justify="center" /*alignItems="center" className={classes.root}*/>
      <Grid item xs={12} sm={5}>
        <FormHeader text="Soupiska" />
        {list(tList)}
      </Grid>
      <Grid item xs={12} sm={2}>
        <Grid container direction={width === "xs" ? "row" : "column"} justify="center" alignItems="center">
          <IconButton color="inherit" edge="start" onClick={handleCheckedRight}
            disabled={leftChecked.length === 0} /* className={classes.menuButton} */>
            {width === "xs" ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </IconButton>
          <IconButton color="inherit" edge="start" onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0} /* className={classes.menuButton} */>
            {width === "xs" ? <KeyboardArrowUpIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormHeader text="Zbývající hráči" />
        {list(aList)}
      </Grid>
    </Grid>
  );
}

export default withWidth()(LeagueList);
