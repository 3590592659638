import { Button, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../App/AuthContext';
import PageLayout from '../Infrastructure/PageLayout';
import PaperPad from '../Infrastructure/PaperPad';
import ProgressPage from '../Infrastructure/ProgressPage';
import { GetAppInfo } from './AdminService';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));

function AppInfo(props) {
    const authCtx = useContext(AuthContext);
    const classes = useStyles();
    const appVersion = process.env.REACT_APP_VERSION
    const appEnvironment = process.env.REACT_APP_ENVIRONMENT
    const [appInfo, setAppInfo] = useState(null);
    
    const onRouteClick = (link) => {
        switch (link){
            case "reg":
                props.history.push({pathname: '/appinfo/register'}); break;
            case "season":
                props.history.push({pathname: '/appinfo/season'}); break;
            case "log":
                props.history.push({pathname: '/appinfo/logs'}); break;
             default: break;
        }
    }

    useEffect(() => {
        const handle = (res) => {
            if (res) {
                if (res.errorCode === undefined)
                    setAppInfo(res);
                else props.history.push({pathname: '/noaccess', state: res});
            } else {
                props.history.push({pathname: '/noaccess', state: {errorCode: -1000, message: ''}});
            }
        }
        GetAppInfo(authCtx.auth.token, handle)
    }, [authCtx.auth.token,props.history])
    
    return (appInfo != null ?
        <PageLayout width='md'>
            <PaperPad width='md'>
                <div>Aplikační prostředí: {appEnvironment}</div>
                <div>Client: {appVersion}</div>
                <div>Web: {appInfo.webVersion}</div>
                <div>Databáze: {appInfo.dbVersion}</div>
                <div className={classes.root}>
                    <Button variant="outlined" color="secondary" onClick={() => { onRouteClick("reg") }}>Registrace</Button>
                    <Button variant="outlined" color="secondary" onClick={() => { onRouteClick("season") }}>Sezóna</Button>
                    <Button variant="outlined" color="secondary" onClick={() => { onRouteClick("log") }}>Logy</Button>
                    {/* <Button variant="outlined" color="secondary">Secondary</Button>
                    <Button variant="outlined" color="secondary">Disabled</Button>
                    <Button variant="outlined" color="secondary">Link</Button> */}
                </div>
            </PaperPad>
        </PageLayout> : <ProgressPage />
    );
}

export default AppInfo;