import React, { useContext, useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Controls from '../../../controls/Controls';
import LeagueList from './LeagueList';
import PageLayout from '../Infrastructure/PageLayout';
import PaperPad from '../Infrastructure/PaperPad';
import AuthContext from '../../App/AuthContext';
import { GetLeagueList, EndSeason, SaveSeason } from './AdminService';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ConfirmDlg from '../Partials/ConfirmDlg';
import Notification from '../../App/Notification';

const useStyles = makeStyles((theme) => ({
    alignright: {
        textAlign: 'right',
    },
}));

function Season(props) {
    const authCtx = useContext(AuthContext);
    const [onRefreshChange, setOnRefreshChange] = useState(false);
    const [league, setLeague] = useState(null);
    const [tl, setTl] = useState(null);
    const closedNotify = { isOpen: false, message: '', type: '', title: '' };
    const [notify, setNotify] = useState(closedNotify);    
    const classes = useStyles();
    
    const initialValues = league ? {
         startDate: league.leagueStart, endDate: league.leagueEnd,
         leagueDescription: league.description, leagueShortDescr: league.shortDescription,
         cbfId: league.cbfId
        }
        :  { startDate: null, endDate: null, leagueDescription: '', leagueShortDescr: '', cbfId: '' };

    const validationSchema = yup.object().shape({
            startDate: yup.date().typeError('Neplatné datum').required('Zadej datum').nullable(),
            endDate: yup.date().typeError('Neplatné datum').required('Zadej datum').nullable(),
            leagueDescription: yup.string().required('Zadej popis'),
            leagueShortDescr: yup.string().required('Zadej zkrácený popis')
    });

    useEffect(() => {
        const handler = (res) => {
            if (res) {
                if (res.errorCode === undefined){
                    setLeague(res);
                    setTl(res.teamList);
                } else props.history.push({ pathname: '/noaccess', state: res });
            } else props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
        }
        GetLeagueList(authCtx.auth.token, null, league ? league.teamId : null, handler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx.auth.token, onRefreshChange]);

    const setTeamList = (list) => {
        setTl(list);
    }

    const saveEndSeason = () => {
        const handle = (res) => {
            if (res && res.errorCode !== -1000) {
                if (res.errorCode === undefined) {
                    if (res.ResultCode === 1) {
                     setOnRefreshChange(!onRefreshChange);
                     setNotify({ isOpen: true, message: 'Sezóna byla ukončena', type: 'success' });
                    } else setNotify({ isOpen: true, message: res.Description, type: 'error' }); 
                } else 
                    setNotify({ isOpen: true, message: 'Nepodařilo se provést požadovanou operaci ', type: 'error' }); 
            } else {
                setNotify({ isOpen: true, message: 'Služba není momentálně přístupná', type: 'error' });
            }
        }
        EndSeason(authCtx.auth.token, league.leagueId, handle);
    }

    const SaveSeasonAndList = (values, onSubmitProps) => {
        const handle = (res) => {
            if (res && res.errorCode !== -1000) {
                if (res.errorCode === undefined) {
                    if (res.ResultCode === 1) {
                     setNotify({ isOpen: true, message: 'Sezóna byla uložena', type: 'success' });
                     setOnRefreshChange(!onRefreshChange);
                    } else setNotify({ isOpen: true, message: res.Description, type: 'error' }); 
                } else 
                    setNotify({ isOpen: true, message: 'Nepodařilo se provést požadovanou operaci ', type: 'error' }); 
            } else {
                setNotify({ isOpen: true, message: 'Služba není momentálně přístupná', type: 'error' });
            }
            onSubmitProps.setSubmitting(false);
        }
        values.leagueId = league.leagueId;
        const data = {
            teamId: league.teamId,
            leagueId: league.leagueId,
            leagueStart: values.startDate,
            leagueEnd: values.endDate,
            description: values.leagueDescription,
            shortDescription: values.leagueShortDescr,
            cbfId: values.cbfId,
            teamList: tl
        };
        SaveSeason(authCtx.auth.token, data, handle);
    }

    const onTeamChange = (e) => {
        var l = league;
        l.teamId = e.target.value;
        setLeague(l);
        setOnRefreshChange(!onRefreshChange);
    }

    return (league &&
        <PageLayout width="lg">
            <PaperPad width='md'>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={SaveSeasonAndList}
                    enableReinitialize
                >{formik => {
                    return(
                      <Form>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controls.DropDownList 
                                name="team" 
                                size="small" 
                                margin="dense" 
                                label="Tým"
                                nullTitle=""
                                value={league ? league.teamId : ''}
                                options={league.teamLOV}
                                onChange={onTeamChange}
                            />                            
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Controls.Input
                                name="leagueShortDescr"
                                label="Zkrácený popis*"
                                size="small"
                                margin="dense"
                            />
                        </Grid>
                        <Grid item xs={12} sm={5} md={5}>
                            <Controls.DatePicker
                                name="startDate"
                                label="Začátek sezóny*"
                                size="small"
                                margin="dense"
                            >
                            </Controls.DatePicker>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5}>
                            <Controls.DatePicker
                                name="endDate"
                                label="Konec sezóny*"
                                size="small"
                                margin="dense"
                            >
                            </Controls.DatePicker>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} /* className={participate ? null : classes.hide} */>
                            <Controls.NumberInput
                                name="cbfId"
                                label="Cbf Id"
                                size="small"
                                margin="dense"
                                format="######"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Controls.Input
                                name="leagueDescription"
                                label="Úplný popis*"
                                size="small"
                                margin="dense"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Controls.Button type="submit"
                                size="small"
                                color={league.leagueId ? "primary" : "secondary"}
                                variant= "contained"
                                disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                text={league.leagueId ? "Uložit" : "Založit novou"}
                                startIcon={<SaveAltIcon />}
                            />
                         </Grid>
                        <Grid item xs={6} className={classes.alignright}>
                            {league.leagueId && <ConfirmDlg
                                name="confirmDlg"
                                parentHandler={saveEndSeason}
                                okLabel="Ukončit"
                                title="Opravdu chceš ukončit sezónu?"
                                disabled={league.leagueId === null }
                            />}
                        </Grid>
                    </Grid>                    
                    </Form>
                    )
                }}
                </Formik>
            </PaperPad>
            <PaperPad width='md'>
                <LeagueList
                    teamList = {league.teamList}
                    availList = {league.availableList}
                    setTeamList = {setTeamList}
                />
            </PaperPad>
            <Notification notify={notify} setNotify={setNotify} enableClickAway={true} />
        </PageLayout>
    );
}

export default Season;
