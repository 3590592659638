import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    sm: {
        padding:theme.spacing(0.5),
    },
    md: {
        padding:theme.spacing(1),
    },
    lg: {
        padding:theme.spacing(2),
    },
}));

function PaperPad(props) {
    const  { width = "md" } = props;
    const classes = useStyles();
    return (
        <Paper className={width === 'sm' ? classes.sm : width === 'lg' ? classes.lg : classes.md}>
            { props.children }
        </Paper>
    );
}

export default PaperPad;