import {callWebApiAnonymous, callHandleResult } from "../../../services/WebApi";

export const Reset = (password, confirmPassword, hash, handler) => {
    const handleSaveData = (res) => {
        callHandleResult(res, handler);
    }    
    callWebApiAnonymous('ResetPassword', 'PUT', { "password": password, "confirmPassword": confirmPassword, "hash": hash }, handleSaveData);
}

export const GetResetToken = (email, handler) => {
    const handleSaveData = (res) => {
        callHandleResult(res, handler);
    }    
    callWebApiAnonymous('ResetPassword', 'POST', { "email": email }, handleSaveData);
}
