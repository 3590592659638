import React from 'react'
import PageLayout from '../AppPages/Infrastructure/PageLayout';
import PaperPad from '../AppPages/Infrastructure/PaperPad';
import AccessibleIcon from '@material-ui/icons/Accessible';
import { IconHeader } from '../AppPages/Infrastructure/PageElements';
import { Typography } from '@material-ui/core';

function ErrorPage (props) {
    let message = 'V systému došlo k chybě, kontaktuj podporu.';
    if (props && props.location && props.location.state)
    switch (props.location.state.errorCode){
      case 1: message = props.location.state.message; break;
      case -1: message = 'Pokus o neautorizovaný přístup, přihlaš se.';break;
      case -2: message = 'Na provedení akce nemáš příslušné oprávnění. Buď kontaktuj správce systému, nebo to už nezkoušej.';break;
      
      case -1000: message = 'Služba není momentálně přístupná, zkus později nebo kontaktuj podporu.';break;
      default: 
        break;
    }
    
    return (
        <PageLayout width="sm">
            <PaperPad width='lg'>
                <IconHeader text="">
                    <AccessibleIcon />
                </IconHeader>
                <Typography>{message}</Typography>
            </PaperPad>
        </PageLayout>
    );
}
export default ErrorPage;