import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../App/AuthContext';
import { FormHeader } from '../Infrastructure/PageElements';
import PageLayout from '../Infrastructure/PageLayout';
import ProgressPage from '../Infrastructure/ProgressPage';
import { GetPayments } from './AdminService';
import { GetLOVs } from '../Infrastructure/PublicApi';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DisplayValue from '../Partials/DisplayValue';
import { Pagination } from '@material-ui/lab';
import { green, red } from '@material-ui/core/colors';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FilterListIcon from '@material-ui/icons/FilterList';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { Form, Formik } from 'formik';
import Controls from '../../../controls/Controls';
import TreasuryDlg from './TreasuryDlg';


function Treasury(props) {
    const useStyles = makeStyles((theme) => ({
        header: {
            paddingBottom: theme.spacing(0.5),
        },
        filter: {
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
        },
        pager: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        root: {
            width: '100%',
        },
        summary: {
            "& .MuiGrid-item p": {
                fontSize: theme.typography.pxToRem(14),
            }
        },
        detail: {
            paddingTop: "0",
            paddingBottom: theme.spacing(0.5),
            "& .MuiGrid-item p": {
                fontSize: theme.typography.pxToRem(14),
            }
        },
        amount: {
            textAlign: 'right',
            paddingRight: theme.spacing(1),
        },
        due: {
            color: theme.palette.warning.main
        },
        unpaid: {
            color: theme.palette.info.main
        },
        alignleft: {
            textAlign: 'left',
        },
        alignright: {
            textAlign: 'right',
        },
        total: {
            marginTop: theme.spacing(0.25),
            padding: theme.spacing(0.5),
            textAlign: 'right'
        },
        total1: {
            padding: theme.spacing(1.2),
            fontSize: '90%',
        },
    }));

    const initialFilterValues = {textfilter: '', unpaid: '1', trtype: '', dueDate: null};
    const initialPagerValues = {pagecnt: '6'};
    const classes = useStyles();
    const authCtx = useContext(AuthContext);
    const [cashList, setCashList] = useState({cashTransactions: null, countPerPage: initialPagerValues.pagecnt, currentPage: 1});
    const [expanded, setExpanded] = useState(null);
    const [onRefreshChange, setOnRefreshChange] = useState(false);
    const [paymentTypeLOV, setPaymentTypeLOV] = useState(null);
    const [filterValues, setFilterValues] = useState(initialFilterValues);
    const [paymentDlg, setPaymentDlg] = useState({transaction: null, action: ''});

    useEffect(() => {
        const handle = (res) => {
            if (res) {
                if (res.errorCode === undefined)
                    setCashList(res);
                else props.history.push({ pathname: '/noaccess', state: res });
            } else {
                props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
            }
        }
        GetPayments(authCtx.auth.token, handle, cashList.countPerPage, cashList.currentPage, filterValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx.auth.token, props.history, onRefreshChange])

    const refreshHandler = () => {
        setOnRefreshChange(!onRefreshChange);
    }

    const handleFilter = (values) => {
        setFilterValues(values);
        setCashList({...cashList, currentPage: 1});
        refreshHandler();
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const deleteTransaction = (transaction) => {
        setPaymentDlg({...paymentDlg, transaction: { transaction: transaction, payment: null}, action: 'deltra'});
    }
    const insertPayment = (transaction) => {
        setPaymentDlg({...paymentDlg, transaction: { transaction: transaction, payment: null}, action: 'inspay'});
    }
    const deletePayment = (transaction, payment) => {
        setPaymentDlg({...paymentDlg, transaction: {transaction: transaction, payment: payment}, action: 'delpay'});
    }
    const pageChanged = (event, value) => {
        setExpanded(null);
        setCashList({...cashList, currentPage: value});
        refreshHandler();
    }
    const handleCountChanged = (event) => {
        setCashList({...cashList, countPerPage: event.target.value})
        refreshHandler();
    }

    const handlePayType = (/*event*/) => {
        if (!paymentTypeLOV) {
            const lovHandler = (res) => {
                if (res && res.errorCode === undefined) {
                    setPaymentTypeLOV(res.paytypes);
                }
            }
            GetLOVs(['paytypes'], lovHandler);            
        }
    }

    const goGenPayments = () => {
        props.history.push('/treasury/genpay'); 
    }
    const goBalance = () => {
        props.history.push('/treasury/balance'); 
    }

    return (
        cashList.cashTransactions !== null ?
            (<PageLayout width="md">
                <Paper className={classes.filter}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Button
                                size="small"
                                color="primary"
                                variant= "contained"
                                endIcon={<AccountBalanceWalletIcon />}
                                onClick={goBalance}
                                >
                                    Zůstatek
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <FormHeader className={classes.header} text="Správa pokladny" />
                        </Grid>
                        <Grid item xs={4} className={classes.alignright}>
                            <Button 
                                size="small"
                                color="primary"
                                variant= "contained"
                                endIcon={<AccountBalanceIcon />}
                                onClick={goGenPayments}
                                >
                                    Nové platby
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.filter}>
                    <Formik
                    initialValues={initialFilterValues}
                    onSubmit={handleFilter}
                    >
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={3}>
                                <InputLabel shrink htmlFor="textfilter">Příjmení</InputLabel>
                                <Controls.Input name="textfilter" size="small" margin="none" />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <InputLabel shrink htmlFor="unpaid">Stav platby</InputLabel>
                                <Controls.DropDownList name="unpaid" size="small" margin="none"
                                    options={[
                                        { id: '1', title: 'Nezaplacená' },
                                        { id: '2', title: 'Po splatnosti' },
                                      ]                                    
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <InputLabel shrink htmlFor="dueDate">Datum splatnosti</InputLabel>
                                <Controls.DatePicker name="dueDate" size="small" margin="none"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <InputLabel shrink htmlFor="trtype">Typ platby</InputLabel>
                                <Controls.DropDownList name="trtype" size="small" margin="none" onOpen={handlePayType}
                                    options={ paymentTypeLOV }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button 
                                    size="small"
                                    color="secondary"
                                    variant= "outlined"
                                    type="submit"
                                    endIcon={<FilterListIcon/>}
                                    >
                                        Filtrovat
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>                        
                    </Formik>
                </Paper>
                <div className={classes.root}>
                    {
                        cashList.cashTransactions.map(item => {
                            return (
                                <Accordion key={item.id} expanded={item.id === expanded} onChange={handleChange(item.id)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} id={item.id}>
                                        <Grid container className={classes.summary}>
                                            <Grid item xs={6} sm={3}>
                                                <Typography>
                                                    <DisplayValue value={item.dueDate} type='date' />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={3} className={classes.amount}>
                                                <Typography>
                                                    <DisplayValue className={!item.paidDate ? (item.dueDebt ? classes.due : classes.unpaid) : null} value={item.amount} type="currency" />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography>
                                                    <DisplayValue value={item.description} />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <Typography>
                                                    <DisplayValue value={item.userName} />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <Typography style={{fontStyle:'italic'}}>
                                                    <DisplayValue value={item.transType} /> {/* #{item.id} */}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.detail}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="outlined"
                                                    color='secondary'
                                                    component="span"
                                                    size="small"
                                                    startIcon={<CancelIcon style={{ fontSize: 14, color: red[500] }} />}
                                                    onClick={() => { deleteTransaction(item) }}
                                                >
                                                    Odstranit celou transakci
                                                </Button>
                                            </Grid>
                                            {item.userName &&
                                            <>
                                                <Grid item xs={12}>
                                                    <FormHeader text="platby" align="left" />
                                                </Grid>
                                                {item.debt !== 0 &&
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={3}>
                                                                <Typography>Zbývá zaplatit</Typography>
                                                            </Grid>
                                                            <Grid item xs={2} className={classes.amount}>
                                                                <Typography>
                                                                    <DisplayValue value={item.debt} type="currency" />
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <Button
                                                                    variant="outlined"
                                                                    color='secondary'
                                                                    component="span"
                                                                    size="small"
                                                                    startIcon={<CheckCircleIcon style={{ fontSize: 14, color: green[500] }} />}
                                                                    onClick={() => { insertPayment(item) }}
                                                                >
                                                                    Zaplatit
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                }
                                                {item.payments.map(payment => {
                                                    return (
                                                    <Grid key={payment.id} item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={3}>
                                                                <Typography>
                                                                    <DisplayValue value={payment.date} type="date" />
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2} className={classes.amount}>
                                                                <Typography>
                                                                    <DisplayValue value={payment.amount} type="currency" />
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <Button
                                                                    variant="outlined"
                                                                    color='secondary'
                                                                    component="span"
                                                                    size="small"
                                                                    startIcon={<CancelIcon style={{ fontSize: 14, color: red[500] }} />}
                                                                    onClick={() => { deletePayment(item, payment) }}
                                                                >
                                                                    Smazat
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    )
                                                })
                                                }
                                            </>
                                            }
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    }
                </div>
                <Paper>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={8} md={9}>
                            <Pagination className={classes.pager}
                                count={Math.ceil(cashList.totalTransactions / cashList.countPerPage)}
                                page={cashList.currentPage}
                                onChange={pageChanged}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <div className={classes.total}>
                                <FormControl margin='none' variant='outlined' size='small'>
                                    <Select
                                        value={cashList.countPerPage}
                                        onChange = {handleCountChanged}
                                        color='secondary'
                                >    
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={12}>12</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl margin='none' size='small'>
                                    <DisplayValue className={classes.total1} value={
                                        ((cashList.currentPage - 1) * cashList.countPerPage + (cashList.totalTransactions > 0 ? 1 : 0)).toString() + ' - ' 
                                    + (Math.min((cashList.currentPage) * cashList.countPerPage, cashList.totalTransactions)).toString() + ' / ' 
                                    + cashList.totalTransactions.toString()
                                    } 
                                    />
                                </FormControl>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
                <TreasuryDlg 
                    name="paymentdlg" 
                    transaction={paymentDlg} 
                    setTransaction={setPaymentDlg} 
                    refreshHandler={refreshHandler} 
                />
            </PageLayout>
            ) : (<ProgressPage />)
    );
}

export default Treasury;