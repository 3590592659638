import { Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../App/AuthContext';
import { FormHeader } from '../Infrastructure/PageElements';
import PageLayout from '../Infrastructure/PageLayout';
import ProgressPage from '../Infrastructure/ProgressPage';
import { GetMyPayments } from './DashboardService';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DisplayValue from '../Partials/DisplayValue';
import { isInRole } from '../../../services/AuthApi';

function CashPayments(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        date: {
            flexShrink: 2,
            flexBasis: '25%',
        },
        cash: {
            padding: theme.spacing(1),
        },
        amount: {
            flexShrink: 2,
            flexBasis: '25%',
            textAlign: 'right',
            paddingRight: theme.spacing(1),
            "& span.due": { color: theme.palette.warning.main },
            "& span.unpaid": { color: theme.palette.info.main },
        },
        description: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '50%',
            flexShrink: 4,
            color: theme.palette.text.secondary,
        },
        link: {
            "&:hover": {
                textDecoration: 'underline',
            },
        },
        detail: {
            width: '50%',
            color: theme.palette.text.secondary,
            "& div": { display: 'flex', },
            "& div p": {
                flexBasis: '45%',
                paddingLeft: theme.spacing(2),
                fontSize: theme.typography.pxToRem(15),
            },
        },
        right: { textAlign: 'right', },
        center: { textAlign: 'center', },
    }));

    const classes = useStyles();
    const authCtx = useContext(AuthContext);
    const [cashList, setCashList] = useState(null);
    const [expanded, setExpanded] = useState(null);
    const [payCount, setPayCount] = useState(4);

    useEffect(() => {
        const handle = (res) => {
            if (res) {
                if (res.errorCode === undefined)
                    setCashList(res);
                else props.history.push({ pathname: '/noaccess', state: res });
            } else {
                props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
            }
        }
        GetMyPayments(authCtx.auth.token, handle, payCount)
    }, [authCtx.auth.token, props.history, payCount])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const goMore = () => {
        setPayCount(payCount + 4);
    }
    return (
        cashList !== null ?
            (<PageLayout width="md">
                {isInRole(authCtx.auth, 'Player') && <FormHeader text="Pokladna" />}
                <Grid container spacing={1}>
                    {
                        <>
                            {isInRole(authCtx.auth, 'Player') &&
                            <Grid item xs={12}>
                                <Paper className={classes.cash} variant="outlined">
                                    <Grid container>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={6} className={classes.center}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    Aktuální stav:
                                                </Grid>
                                                <Grid item xs={6} className={classes.right}>
                                                    <DisplayValue value={cashList.cashBalance.Balance} type="currency" />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    Nezaplaceno:
                                                </Grid>
                                                <Grid item xs={6} className={classes.right}>
                                                    <DisplayValue value={cashList.cashBalance.Debt} type="currency" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            }
                            <Grid item xs={12} className={classes.root}>
                                <FormHeader text="Moje platby" />
                                {
                                    cashList.myTransactions.map(item => {
                                        return (
                                            <Accordion key={item.id} expanded={item.id === expanded} onChange={handleChange(item.id)} >
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} id={item.id}>
                                                    <Typography className={classes.date}>
                                                        <DisplayValue value={item.dueDate} type='date' />
                                                    </Typography>
                                                    <Typography className={classes.amount}>
                                                        <DisplayValue className={!item.paidDate ? (item.dueDebt ? 'due' : 'unpaid') : null} value={item.amount} type="currency" />
                                                    </Typography>
                                                    <Typography className={classes.description}>
                                                        <DisplayValue value={item.description} />
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className={classes.detail}>
                                                        <FormHeader text="platby" align="left" />
                                                        {item.debt !== 0 ?
                                                            <div>
                                                                <Typography>Zbývá zaplatit</Typography>
                                                                <Typography className={classes.right}>
                                                                    <DisplayValue value={item.debt} type="currency" />
                                                                </Typography>
                                                            </div> : null
                                                        }
                                                        {
                                                            item.payments.map(payment => {
                                                                return (
                                                                    <div key={payment.id}>
                                                                        <Typography>
                                                                            <DisplayValue value={payment.date} type="date" />
                                                                        </Typography>
                                                                        <Typography className={classes.right}>
                                                                            <DisplayValue value={payment.amount} type="currency" />
                                                                        </Typography>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.link} color='secondary' onClick={goMore}>... další ...</Typography>
                            </Grid>
                        </>
                    }
                </Grid>
            </PageLayout>) : (<ProgressPage />)
    );
}

export default CashPayments;