import React, { useEffect, useState } from 'react';
import { FormHeader } from '../Infrastructure/PageElements';
import PageLayout from '../Infrastructure/PageLayout';
import ProgressPage from '../Infrastructure/ProgressPage';
import LeagueTable from '../Partials/LeagueTable';
import { GetTablesData } from './HomeService';


function Tables(props) {
    const [apiData, setApiData] = useState(null);

    useEffect(() => {
        const handler = (res) => {
            setApiData(res);
        }
        GetTablesData(handler);
    }, []);

    return (
        apiData ? (
        <PageLayout  width="sm">
            {
                apiData.length > 0 ?
                apiData.map(t => (
                    <LeagueTable key={t.id} data={t} />
                )) : <FormHeader text="V současné době neprobíhá žádná soutěž." />
            }
        </PageLayout>)
        : (<ProgressPage />)
    );
}

export default Tables;