import { Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles } from '@material-ui/core';
import DisplayValue, { DisplayAddress, DisplayList } from './DisplayValue';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PersonalStats from './PersonalStat';

const useStyles = makeStyles((theme) => ({
    alignright: {
        textAlign: 'right',
    },
    won: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
    }
}));

function GameStats(props) {
    const classes = useStyles();
    const { data = null, expanded=null, setExpanded=null } = props;
    const handlePanelChange = (panel) => (event, isExpanded) => {
        if (setExpanded !== null) {
            setExpanded(isExpanded ? panel : false);
        }
    };

    const getTeamClass = (won) => {
      return won ? classes.won : null;
    }

    return (
        <Accordion expanded={data.game.id === expanded} onChange={handlePanelChange(data.game.id)} >
            <AccordionSummary expandIcon={setExpanded && <ExpandMoreIcon />} /* id={...id} */>
                <Grid container>
                    <Grid item xs={8}>
                            {data.game.round}. <span className={getTeamClass(data.game.scoreA >= data.game.scoreB)}>{data.game.teamAName}</span> - <span className={getTeamClass(data.game.scoreA <= data.game.scoreB)}>{data.game.teamBName}</span>
                    </Grid>
                    <Grid item xs={4} className={classes.alignright}>
                        {data.game.scoreA} : {data.game.scoreB}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {setExpanded && 
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <DisplayValue value={data.game.gameDate} type="date"/> <DisplayAddress address={data.game.address} city={data.game.city}/>
                    </Grid>
                    <Grid item xs={4} className={classes.alignright}>
                        <DisplayList list={data.game.partials} itemFn={(item) => { return item.scoreA + ":" + item.scoreB; }}/>
                    </Grid>
                    <Grid item xs={12}>
                        <PersonalStats data={data.stats} summary={data.game.summary} margin={false} participation={false} />
                    </Grid>
                </Grid>
                }
            </AccordionDetails>
        </Accordion>
);
}

export default GameStats;