import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from '@material-ui/core';
import { Field } from 'formik';

export default function DropDownList(props) {
    const { name, label, variant="outlined", margin="normal", size='medium', nullTitle="... všechno", options, ...other } = props;
    return (
        <Field name={name}>
            {({form, field}) => {
                const error = form.errors[field.name];
                const touched = form.touched[field.name];
                return (<FormControl margin={margin} variant={variant} size={size} fullWidth>
                    <InputLabel color='secondary' error={error && touched}>{label}</InputLabel>
                    <MuiSelect 
                        label={label}
                        name={name}
                        value={field.value}
                        onChange = {field.onChange}
                        onBlur = {field.onBlur}
                        color='secondary'
                        /* {...(error && {error: true, helperText: error})} */
                        error={error && touched}
                        {...other}
                    >
                        {nullTitle !== '' ? (<MenuItem style={{fontStyle: 'italic'}} value="">{nullTitle}</MenuItem>) : null}
                        {options ? options.map(item => (
                            <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                        )) : null}
                    </MuiSelect>
                    <FormHelperText error={error && touched}>{touched && error}</FormHelperText>
                </FormControl>)
            }
            }
        </Field>
    )
}
