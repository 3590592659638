import React, { useContext, useState } from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Controls from '../../../controls/Controls';
import { GetUserByPassword } from '../../../services/AuthApi';
import AuthContext from '../../App/AuthContext';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageLayout from '../Infrastructure/PageLayout';
import { FormErrorMessage, IconHeader } from '../Infrastructure/PageElements';
import PaperPad from '../Infrastructure/PaperPad';

function LoginPage(props) {
    let authCtx = useContext(AuthContext);
    const [errMsg, setErrMsg] = useState('');

    const initialValues = {
        userName: "",
        password: "",
        remember: false
    }

    const validationSchema = yup.object().shape({
        userName: yup.string('').required('Zadej svoje přihlašovací jméno'),
        password: yup.string('').required('Zadej heslo'),
    });

    const onSubmit = (values, onSubmitProps) => {
        const handleLogin = (res, refreshToken) => {
            onSubmitProps.setSubmitting(false);
            if (res) {
                if (res.isAuthenticated) {
                    authCtx.setAuth(res);
                    if (values.remember) {
                        Cookies.set("authtoken", refreshToken, { expires: 50000 });
                    } else {
                        Cookies.remove("authtoken");
                    }
                    props.history.push('/');
                } else {
                    setErrMsg("Přihlašovací údaje nejsou správné");
                }
            } else {
                props.history.push('/noaccess');
            }
        }
        GetUserByPassword(values.userName, values.password, handleLogin);
    }

    const goResetPassword = (e) => {
        props.history.push('/forgotten');
    }

    return (
        <PageLayout width="sm">
            <PaperPad width='lg'>
                <IconHeader text="Přihlášení">
                    <LockOutlinedIcon />
                </IconHeader>
                <FormErrorMessage message={errMsg} />
                <Formik
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >{formik => {
                    return (
                        <Form>
                            <Controls.Input
                                name="userName"
                                label="Přihlašovací jméno"
                            />
                            <Controls.Input
                                name="password"
                                label="Heslo"
                                type="password"
                            />
                            <Controls.Checkbox
                                name="remember"
                                label="Zapamatovat"
                            //color="secondary"
                            />
                            <Controls.Button fullWidth type="submit" text="Přihlásit"
                                disabled={formik.isSubmitting} />
                            <Grid container>
                                <Grid item xs>
                                    <Link color="secondary" variant="body2" component="button" onClick={goResetPassword}>Zapomenuté heslo?</Link>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }
                    }
                </Formik>
            </PaperPad>
        </PageLayout>
    );
}

export default withRouter(LoginPage);