import React from 'react';
import { KeyboardDateTimePicker as MuiDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import "moment/locale/cs";
import { Field } from 'formik';

class LocalizedUtils extends MomentUtils {
    getDatePickerHeaderText(date) {
        return this.format(date, "dd, D. MMM");
    }
}

export default function DateTimePicker(props) {
    moment.locale("cs");

    const { name, label, margin = "normal", ...rest } = props;
    //let errorText = '';

    return (
        <Field name={name}>
            {({ form, field }) => {
                const errorText = form.errors[field.name];
                const touched = form.touched[field.name];
                return (
                    <MuiPickersUtilsProvider libInstance={moment} utils={LocalizedUtils} locale={"cs"}>
                        <MuiDatePicker variant="inline" inputVariant="outlined" label={label}
                            autoOk
                            clearable="true"
                            invalidDateMessage="Nesprávně zadané datum"
                            maxDateMessage="Datum je příliš velké"
                            minDateMessage="Datum je příliš malé"
                            orientation="portrait"
                            fullWidth
                            openTo="date"
                            margin={margin}
                            format="DD. MM. yyyy HH:mm"
                            spellCheck="false"
                            autoComplete="off"
                            ampm={false}
                            views={["year", "month", "date", "hours", "minutes"]}
                            name={name}
                            value={field.value}
                            onChange={date => {
                                form.setFieldValue(field.name, date, true)
                            }}
                            onBlur={field.onBlur}
                            color="secondary"
                            error={Boolean(errorText) && touched}
                            helperText={touched && errorText}
                            onError={error => {
                                if (touched && Boolean(error) && !Boolean(errorText)) {
                                    form.setFieldError(field.name, error);
                                }
                            }}
                            {...rest}
                        />
                    </MuiPickersUtilsProvider>
                )
            }
            }
        </Field>
    )
}