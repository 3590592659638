import React/* , { useState } */ from 'react';
import { KeyboardDatePicker as MuiDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// pick a date util library, depens on utils is used by DatePicker
import moment from "moment";
import MomentUtils from '@date-io/moment';
import "moment/locale/cs";
import {Field} from 'formik';

//import DateFnsUtils from '@date-io/date-fns';
//import LuxonUtils from '@date-io/luxon';
//import csLocale from "date-fns/locale/cs";

//import { FormControl, FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';

/* class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date) {
      return "měsíc"; // this.format(date, "d MMM yyyy", { locale: this.locale });
    }
    getCalendarHeaderText(date) {
        return this.format(date, "MMM yyyy", { locale: this.locale });  
    }
}
 */
  class LocalizedUtils extends MomentUtils {
     getDatePickerHeaderText(date) {
      //return "měsíc"; 
      return this.format(date, "dd, D. MMM");
    } 
    /* date(date){
        return date;
    } */
/*      getCalendarHeaderText(date) {
        return "xxxxx"; //return this.format(date, "MMM yyyy", { locale: this.locale });  
    }
 */
}


 
 export default function DatePicker(props) {
    moment.locale("cs");
    
    const { name, label, margin="normal", ...rest } = props;
    //let errorText = '';
    //const [selectedDate, handleDateChange] = useState(new Date());

    /* const convertToDefEventPara = (name, value) => ({
        target: { name, value }
    }) */
    /* <MuiPickersUtilsProvider utils={LocalizedUtils} locale={csLocale}> */
    
    return (
        <Field name={name}>
            {({form, field}) => {
                const errorText = form.errors[field.name];
                const touched = form.touched[field.name];
                return (
                    <MuiPickersUtilsProvider libInstance={moment} utils={LocalizedUtils}  locale={"cs"}>
                    <MuiDatePicker variant="inline" inputVariant="outlined" label={label}
                        autoOk
                        clearable="true"
                        invalidDateMessage="Nesprávně zadané datum"
                        maxDateMessage = "Datum je příliš velké"
                        minDateMessage = "Datum je příliš malé"
                        orientation="portrait"
                        fullWidth
                        openTo="date"
                        margin={margin}
                        format= "DD. MM. yyyy" //"DD. MM. yyyy HH:mm"
                        spellCheck="false"
                        autoComplete="off"
                        //ampm = {false}
                        //views={["date","month","year"]}
                        //disableToolbar
                        //animateYearScrolling="true"
                        views={["year", "month", "date"/* , "hours", "minutes" */]}
                        name={name}
                        value={field.value}
                        //onChange = {(date) => {field.onChange(convertToDefEventPara(name, date))}}
                        onChange={date => { 
                            form.setFieldValue(field.name, date, true)
                        }}
                        onBlur={field.onBlur}
                        color="secondary"
                        //{...(error && {error: true, helperText: error})}
                        error={Boolean(errorText) && touched}
                        helperText = {touched && errorText}
                        onError={error => { 
                            if (touched && Boolean(error) && !Boolean(errorText)) {
                                form.setFieldError(field.name, error);
                            }
                        }}
                       {...rest}
                     />
                </MuiPickersUtilsProvider>
                )
            }
            }
        </Field>
    )
}