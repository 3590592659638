import moment from 'moment';

const round = (value, decimals) => {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

export const fmtPhone = (value) => {
    return value && value !== '' ? '+' + value.match(/...?/g).join(' ') : '+420 ... ... ...';
}

export const fmtDate = (value) => {
    moment.locale("cs");
    let fmt = moment(value);
    return fmt.isValid() ? fmt.format('D.M.YYYY') : value; 
}

export const fmtDateTime = (value) => {
    moment.locale("cs");
    let fmt = moment(value);
    return fmt.isValid() ? fmt.format('D.M.YYYY HH:mm') : value; 
}

export const fmtName = (firstName, lastName, title) => {
    return (title && title !== '' ? title + " " : "") +  firstName + ' ' + lastName;
}

export const fmtDayOfWeek = (value) => {
    moment.locale("cs");
    let fmt = moment(value);
    return fmt.isValid() ? fmt.format('dddd') : value; 
}

export const fmtNumber = (value, decimals=0) => {
    return value.toLocaleString("cs", {minimumFractionDigits: decimals, maximumFractionDigits: decimals}) /* + " Kč" */;
    //return value && value !== '' ? value.match(/...?/g).join(' ') : '';
}

export const fmtAddress = (address, city) => {
    return (address ?? '') + ((address && city) ? ', ' + city : (city ?? '')) 
}

export const fmtRate = (value1, value2, decimals=1) => {
    return (value2 ?? 0) > 0 ? round(value1 / value2, decimals) : 0;
}

export const fmtPercentage = (value1, value2, decimals=1) => {
    return fmtRate(value1 * 100, value2, decimals);
}
