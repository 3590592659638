import React, {useContext} from 'react'
import AuthContext from '../../App/AuthContext';
import PageLayout from '../Infrastructure/PageLayout';
import DashBoardAuth from './DashBoardAuth';
import DashBoardNoAuth from './DashBoardNoAuth';

function Dashboard (props) {
    let authCtx = useContext(AuthContext);
        return (authCtx && <PageLayout width="lg"> 
                    {authCtx.auth.isAuthenticated ? 
                    (
                        <DashBoardAuth/>
                    ) :
                    (
                        <DashBoardNoAuth/>
                    )}
                </PageLayout>
                )
}
export default Dashboard;
