import React, { useContext, useState } from 'react';
import AuthContext from '../../App/AuthContext';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@material-ui/core';
import Notification from '../../App/Notification';
import { Form, Formik } from 'formik';
import Controls from '../../../controls/Controls';
import * as yup from 'yup';
import moment from "moment";
import { fmtDate, fmtNumber } from '../Infrastructure/FormattingLib';
import { DeletePayment, InsertPayment, DeleteTransaction } from './AdminService';

export default function TreasuryDlg(props) {
    let authCtx = useContext(AuthContext);
    const closedNotify = { isOpen: false, message: '', type: '', title: '' };
    const { name, transaction = null, setTransaction, refreshHandler } = props;
    const [notify, setNotify] = useState(closedNotify);

    const handleSubmit = (values, onSubmitProps) => {
        const handle = (res) => {
             if (res && res.errorCode !== -1000) {
                if (res.errorCode === undefined) {
                    if (res.ResultCode === 1) {
                    //setNotify({ isOpen: true, message: getActionDescription(values), type: 'success', title: getTitle() });
                    refreshHandler();
                    setTransaction({ ...transaction, transaction: null, action: '' });
                    } else setNotify({ isOpen: true, message: res.Description, type: 'error' }); 
                } else 
                    setNotify({ isOpen: true, message: 'Nepodařilo se provést požadovanou operaci ', type: 'error' }); 
            } else {
                setNotify({ isOpen: true, message: 'Služba není momentálně přístupná', type: 'error' });
            }
            onSubmitProps.setSubmitting(false);
        }
        switch (transaction.action){
            case 'delpay': DeletePayment(authCtx.auth.token, handle, transaction.transaction.payment.id); break;
            case 'inspay': InsertPayment(authCtx.auth.token, handle,transaction.transaction.transaction.id, values.amount, values.payDate); break;
            case 'deltra': DeleteTransaction(authCtx.auth.token, handle, transaction.transaction.transaction.id); break;
            default: break;
        }
    };

    const handleClose = () => {
        setNotify(closedNotify);
        setTransaction({ ...transaction, transaction: null, action: '' });
    };

    function getTitle(){
        switch (transaction.action) {
            case 'delpay': return 'Smazání platby';
            case 'inspay': return 'Platba';
            case 'deltra': return 'Smazání celé transakce';
            default: return '';
        };
    } 

    function getDescription() {
        return (
            transaction.transaction !== null 
            ? transaction.transaction.transaction.description + ' '
            + (transaction.transaction.transaction.userName ? ' - ' + transaction.transaction.transaction.userName : '')
            : ''
        )
    }

    function getActionDescription(values){
        switch (transaction.action) {
            case 'delpay': return fmtDate(transaction.transaction.payment.date) + ' - ' + fmtNumber(maxAmnt) + ' Kč, výsledný dluh ' 
                               + fmtNumber(transaction.transaction.transaction.debt + maxAmnt) + ' Kč';
            case 'inspay': return fmtDate(values.payDate) + ' - ' + fmtNumber(values.amount) + ' Kč'; 
            case 'deltra': return fmtDate(transaction.transaction.transaction.dueDate) + ' - ' + fmtNumber(maxAmnt) + ' Kč';
            default: return ''
        }
    }
    
    const maxAmnt = transaction.transaction !== null 
            ? (transaction.action === 'inspay' 
                ? transaction.transaction.transaction.debt 
                : (transaction.action === 'delpay' 
                    ? transaction.transaction.payment.amount
                    : transaction.transaction.transaction.amount)
              )
            : 0;
    const initialValues = { amount: maxAmnt, payDate: moment() }
    
    const validationSchema = yup.object().shape({
        amount: yup.number().required('Zadej částku').min(1, 'Minimálně 1 Kč').max(maxAmnt, 'Maximálně ' + maxAmnt.toString() + ' Kč'),
        payDate: yup.date().nullable().typeError('').required('Zadej datum platby'),
    });

    return (
        transaction.transaction !== null &&
            <><Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {
                    formik => {
                        return (
                                <Dialog id={name} 
                                    open={transaction.transaction !== null} 
                                    onClose={handleClose} 
                                    disableBackdropClick={true} 
                                    fullWidth={true}
                                >
                                    <Form>
                                        <DialogTitle>{getTitle()}</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                {/* <span style={{fontStyle: 'italic', fontSize: '90%'}}>{getPayType()}</span> */}
                                                {getDescription()}
                                            </DialogContentText>
                                            {transaction.action === 'inspay' ?
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Controls.DatePicker
                                                            name="payDate"
                                                            label="Datum platby*"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Controls.NumberInput
                                                            name="amount"
                                                            label="Částka*"
                                                            format="###### Kč"
                                                            align='left'
                                                        />
                                                    </Grid>
                                                </Grid>
                                                : <Typography>
                                                    {getActionDescription(null)}
                                                  </Typography>
                                            }
                                        </DialogContent>
                                        <DialogActions>
                                            <Controls.Button onClick={handleClose} color="secondary" variant="outlined" size="small"
                                                text="Zpět"
                                                disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                            />
                                             <Controls.Button 
                                                color="primary" variant="contained" size="small"
                                                text= "OK"
                                                type="submit"
                                                disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                            />
                                        </DialogActions>
                                    </Form>
                                </Dialog>
                        )
                    }
                }
            </Formik>
            <Notification notify={notify} setNotify={setNotify} enableClickAway={true} />
            </>
        )
}
