import React, { useState } from 'react';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import Typography from '@material-ui/core/Typography';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Controls from '../../../controls/Controls';
import { GetResetToken } from './AccountService';
import PageLayout from '../Infrastructure/PageLayout';
import { FormErrorMessage, IconHeader } from '../Infrastructure/PageElements';
import PaperPad from '../Infrastructure/PaperPad';

export default function ForgottenPassword(props) {
    const [errMsg, setErrMsg] = useState('');
    const [again, setAgain] = useState(false);

    const validationSchema = yup.object().shape({
        email: yup.string().email('Špatný formát emailové adresy').required('Zadej emailovou adresu')
    });

    const initialValues = {
        email: ''
    }

    const sendAgain = () => {
        setErrMsg('');
        setAgain(false);
    }

    const onSubmit = (values, onSubmitProps) => {
        const handleResetPassword = (res) => {
            if (res) {
                if (res.errorCode === undefined) {
                    if (res.ResultCode === 1)
                        setAgain(true);
                    else setErrMsg(res.Description);
                } else props.history.push({ pathname: '/noaccess', state: res });
            } else props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
            onSubmitProps.setSubmitting(false);
        }
        GetResetToken(values.email, handleResetPassword);
    }

    return (
        <PageLayout width="sm">
            <PaperPad width='lg'>
                <IconHeader text="Zapomenuté heslo">
                    <LockOpenOutlinedIcon />
                </IconHeader>
                <div style={{ "display": again ? "none" : "block" }}>
                    <Typography component="p" variant="body1">
                        Pokud si přeješ obnovit heslo, zadej zde svůj email uvedený při registraci.
                        Odkaz pro nastavení nového hesla bude odeslán na tuto adresu.
                </Typography>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {formik => {
                            return (
                                <Form>
                                    <Controls.Input
                                        name="email"
                                        label="Email"
                                    />
                                    <FormErrorMessage message={errMsg} />
                                    <Controls.Button fullWidth type="submit" text="Odeslat"
                                        disabled={formik.isSubmitting} />
                                </Form>
                            )
                        }
                        }
                    </Formik>

                </div>

                <div style={{ "display": again ? "block" : "none" }}>
                    <Typography component="p" variant="body1">
                        Email byl odeslán na uvedenou adresu a obsahuje odkaz pro nastavení nového hesla.
                        Změna musí být provedena do 15 minut, potom bude odkaz neplatný.
                </Typography>
                    <Controls.Button fullWidth type="submit" onClick={sendAgain} text="Opakovat odeslání" />
                </div>
            </PaperPad>
        </PageLayout>
    );
}
