import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        '& .league': {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: theme.spacing(0.5),
            '& .title': { color: theme.palette.secondary.main, width: '100%' },
            '& .result': {
                color: theme.palette.text.secondary,
                fontStyle: 'italic',
                marginLeft: theme.spacing(1.5),
                marginRight: theme.spacing(1.5),
            },
            '& .club': {
                color: theme.palette.text.primary,
                marginLeft: theme.spacing(1.5),
                marginRight: theme.spacing(1.5),
            },
        },
    },
}));

function LastGames(props) {
    const { results = null } = props;
    const classes = useStyles();

    return (
        results &&
        <Paper className={classes.root}>{
            results.map(l => {
                return (
                    l.results.length > 0 && <div key={l.leagueId} className='league'>
                        <div className='title'>{l.leagueDescription}</div>
                        {l.results.map(r => {
                            return (
                                <div key={r.cbfId} className={r.clubGame ? 'club' : 'result'}>
                                    {`${r.teamAName} - ${r.teamBName} ${r.scoreA} : ${r.scoreB}`}
                                </div>)
                        })
                        }
                    </div>)
            })
        }
        </Paper>
    );
}

export default LastGames;