import React from 'react'
import { Snackbar, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    root: {
        top: theme.spacing(9)
    }
}))

export default function Notification(props) {

    const { notify, setNotify, duration=3000, enableClickAway=false } = props;
    const classes = useStyles()

    const handleClose = (event, reason) => {
        if (!enableClickAway && reason === 'clickaway') { // do not close on click outside of notification element
            return;
        }
        setNotify({...notify, isOpen: false })
    }
    let titl = notify.title;
    if (!titl) {
        switch(notify.type) {
            case "error": titl="Chyba"; break;
            case "warning": titl="Upozornění"; break;
            case "success": titl="Úspěch"; break;
            case "info": titl="Informace"; break;
            default: titl="Zpráva"; break; 
        }
    }
    return (
        <Snackbar
            className={classes.root}
            open={notify.isOpen}
            autoHideDuration={duration}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleClose}
            >
            <Alert
                severity={notify.type}
                onClose={handleClose}
                variant="filled"
                >
                <AlertTitle>
                    {titl}
                </AlertTitle>
                {notify.message}
            </Alert>
        </Snackbar>
    )
} 