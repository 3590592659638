import {callWebApiAuth, callHandleResult } from "../../../services/WebApi";

export const GetGaleryList = (token, handler) => {
    const handleGetData = (res) => {
      callHandleResult(res, handler);
    }
    callWebApiAuth('Gallery', 'GET', null, token, handleGetData);
  }

export const GetMyPayments = (token, handler, count=4) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Cash', 'GET', {count: count}, token, handleGetData);
}

export const GetDashboardData = (token, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Dashboard', 'GET', null, token, handleGetData);
}

export const GetScheduleData = (token, handler, count=4, page=1) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Schedule', 'GET', {count: count, page: page}, token, handleGetData);
}

export const SaveParticipation = (token, id, value, description, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Schedule', 'PUT', {"id": id, "participate": value, "description": description}, token, handleGetData);
}

export const SaveReferee = (token, id, value, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Referee', 'POST', {"id": id, "refereeType": value }, token, handleGetData);
}

export const SwitchNewsLike = (token, id, value, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Dashboard', 'PUT', {"newsid": id, "value": value}, token, handleGetData);
}

export const SaveNews = (token, values, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Dashboard', 'POST', values, token, handleGetData);
}

export const DeleteNews = (token, id, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Dashboard', 'DELETE', {id: id}, token, handleGetData);
}

export const GetArchiveFilter = (token, filter, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  let teamId = filter ? filter.teamId : "";
  let leagueId = filter ? filter.leagueId : "";
  callWebApiAuth('Archive', 'GET', {teamId: teamId, leagueId: leagueId}, token, handleGetData);
}

export const GetTopPerformance = (token, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Archive', 'GET', null, token, handleGetData);
}

export const SendPaymentMail = (token, email, mailType, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Mailing', 'GET', {email: email, mailType: mailType}, token, handleGetData);
}

export const GetPaymentQR = (token, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('QRCode', 'GET', {qrType: "debt"}, token, handleGetData);
}

export const GetReminder = (token, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Reminder', 'GET', null, token, handleGetData);
}

export const SaveReminder = (token, values, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Reminder', 'POST', values, token, handleGetData);
}