import { Button, FormControl, Grid, InputLabel, makeStyles, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { GetLOVs } from '../Infrastructure/PublicApi';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../App/AuthContext';
import PageLayout from '../Infrastructure/PageLayout';
import ProgressPage from '../Infrastructure/ProgressPage';
import { GetAppLog } from './AdminService';
import { Pagination } from '@material-ui/lab';
import Controls from '../../../controls/Controls';
import DisplayValue from '../Partials/DisplayValue';

const useStyles = makeStyles((theme) => ({
    filter: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    }, 
    pager: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    root: {
        width: '100%',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    item: {
        marginBottom: theme.spacing(1),
    },
    total: {
        marginTop: theme.spacing(0.25),
        padding: theme.spacing(0.5),
        textAlign: 'right'
    },
    total1: {
        padding: theme.spacing(1.2),
        fontSize: '90%',
    },
}));

function Logging(props) {
    const authCtx = useContext(AuthContext);
    const classes = useStyles();
    const [appLogging, setAppLogging] = useState({ logList: null, countPerPage: 8, currentPage: 1 });
    const [logTypeLOV, setLogTypeLOV] = useState(null);
    const initialFilterValues = { logDateFrom: null, logDateTo: null, logType: '' };
    const [filterValues, setFilterValues] = useState(initialFilterValues);
    const [onRefreshChange, setOnRefreshChange] = useState(false);

    useEffect(() => {
        const handle = (res) => {
            if (res) {
                if (res.errorCode === undefined)
                    setAppLogging(res);
                else props.history.push({ pathname: '/noaccess', state: res });
            } else {
                props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
            }
        }
        GetAppLog(authCtx.auth.token, handle, appLogging.countPerPage, appLogging.currentPage, filterValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx.auth.token, props.history, onRefreshChange])

    const handleLogType = (/*event*/) => {
        if (!logTypeLOV) {
            const lovHandler = (res) => {
                if (res && res.errorCode === undefined) {
                    setLogTypeLOV(res.logtypes);
                }
            }
            GetLOVs(['logtypes'], lovHandler);
        }
    }

    const refreshHandler = () => {
        setOnRefreshChange(!onRefreshChange);
    }

    const handleFilter = (values) => {
        setFilterValues(values);
        setAppLogging({ ...appLogging, currentPage: 1 });
        refreshHandler();
    }

    const pageChanged = (event, value) => {
        //setExpanded(null);
        setAppLogging({ ...appLogging, currentPage: value });
        refreshHandler();
    }

    const handleCountChanged = (event) => {
        setAppLogging({ ...appLogging, countPerPage: event.target.value });
        refreshHandler();
    }

    return (appLogging != null && appLogging.logList != null ?
        <PageLayout width='lg'>
            {/* <PaperPad width='lg'> */}
                <Paper className={classes.filter}>
                    <Formik
                    initialValues={initialFilterValues}
                    onSubmit={handleFilter}
                >
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                                <InputLabel shrink htmlFor="logDateFrom">Datum Od</InputLabel>
                                <Controls.DatePicker name="logDateFrom" size="small" margin="none"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <InputLabel shrink htmlFor="logDateTo">Datum Do</InputLabel>
                                <Controls.DatePicker name="logDateTo" size="small" margin="none"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <InputLabel shrink htmlFor="logType">Typ</InputLabel>
                                <Controls.DropDownList name="logType" size="small" margin="none" onOpen={handleLogType}
                                    options={logTypeLOV}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    size="small"
                                    color="secondary"
                                    variant="outlined"
                                    type="submit"
                                    endIcon={<FilterListIcon />}
                                >
                                    Filtrovat
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
                </Paper>    
                <Paper className={classes.root}>
                    {
                        appLogging.logList.map(item => {
                            return (
                                <div className={classes.item} key={item.id}>
                                    <Typography color="textSecondary" variant="caption">
                                        [{item.id}] <DisplayValue value={item.logDate} type="datetime" />  - {item.logTypeDescr} {item.userName}
                                    </Typography>
                                    <Typography>{item.message}</Typography>
                                </div>
                            )
                        })
                    }
                </Paper>
                <Paper>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={8} md={9}>
                            <Pagination className={classes.pager}
                                count={Math.ceil(appLogging.total / appLogging.countPerPage)}
                                page={appLogging.currentPage}
                                onChange={pageChanged}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <div className={classes.total}>
                                <FormControl margin='none' variant='outlined' size='small'>
                                    <Select
                                        value={appLogging.countPerPage}
                                        onChange={handleCountChanged}
                                        color='secondary'
                                    >
                                        <MenuItem value={6}>6</MenuItem>
                                        <MenuItem value={8}>8</MenuItem>
                                        <MenuItem value={12}>12</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl margin='none' size='small'>
                                    <DisplayValue className={classes.total1} value={
                                        ((appLogging.currentPage - 1) * appLogging.countPerPage + (appLogging.total > 0 ? 1 : 0)).toString() + ' - '
                                        + (Math.min((appLogging.currentPage) * appLogging.countPerPage, appLogging.total)).toString() + ' / '
                                        + appLogging.total.toString()
                                    }
                                    />
                                </FormControl>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>

            {/* </PaperPad> */}
        </PageLayout> : <ProgressPage />
    );
}

export default Logging;