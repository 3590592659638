import {callWebApiAuth, callHandleResult } from "../../../services/WebApi";

export const GetProfile = (token, handler) => {
    const handleGetData = (res) => {
      callHandleResult(res, handler);
    }
    callWebApiAuth('Profile', 'GET', null, token, handleGetData);
  }
  
  export const ChangeProfile = (data, token, handler) => {
    const handleSaveData = (res) => {
      callHandleResult(res, handler);
    }
    callWebApiAuth('Profile', 'POST', data, token, handleSaveData);
  }