import React from 'react';
import {TextField} from '@material-ui/core';
import { Field } from 'formik';

export default function Input(props) {
    const {name, label, variant="outlined", margin="normal", ...other} = props;
    return (
        <Field name={name}>
            {
                ({form, field, meta}) => {
                    const error = form.errors[field.name];
                    const touched = form.touched[field.name];
                    return (
                      <TextField 
                        fullWidth
                        margin={margin}
                        variant={variant} 
                        label = {label} 
                        name = {name}
                        value = {field.value} 
                        onChange = {field.onChange}
                        onBlur = {field.onBlur}
                        color="secondary"
                        spellCheck="false"
                        autoComplete="off"
                        {...other}
                        {...(error && touched && {error: true, helperText: error})}
                    />
                    )
                }
            }
        </Field>
    )
}