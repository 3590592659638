import React from "react";

import TextField from "@material-ui/core/TextField";

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;
const OutlinedBox = ({ children, label, color="primary" }) => {
  return (
    <TextField
      variant="outlined"
      label={label}
      color={color}
      multiline
      fullWidth
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent
      }}
      inputProps={{ children: children }}
    />
  );
};
export default OutlinedBox;