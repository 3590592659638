
import React, { useState } from 'react';
import { Button, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import DisplayValue from '../Partials/DisplayValue';
import { FormHeader } from '../Infrastructure/PageElements';
import SendMailDlg from '../Partials/SendMailDlg';
import EmailIcon from '@material-ui/icons/Email';
import CropFreeIcon from '@material-ui/icons/CropFree';
import QRCodeDlg from './QRCodeDlg';

const useStyles = makeStyles((theme) => ({
    header: {
        paddingBottom: theme.spacing(0.5),
    },
    root: {
        padding: theme.spacing(2),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
    due: {
        color: theme.palette.warning.main
    },
    alignright: {
        textAlign: 'right',
    },
    aligncenter: {
        textAlign: 'center',
    },
    marginRight: {
        marginRight: "0.5rem"
    }
}));

function MyDebtCard(props) {
    const { debts, email } = props;
    const classes = useStyles();
    const [dlgOpen, setDlgOpen] = useState(false);
    const [qrOpen, setQrOpen] = useState(false);
    
    const handleSend = () => {
        setDlgOpen(true);
    }

    const handleQR = () => {
        setQrOpen(true);
    }

    return (
        <Paper className={classes.root}>
            <FormHeader className={classes.header} text="Moje dluhy" />
            <Grid container>
                <Grid item xs={6}>
                    <Typography color='textSecondary'>Popis</Typography>
                </Grid>
                <Grid item xs={3} className={classes.aligncenter}>
                    <Typography color='textSecondary'>Splatnost</Typography>
                </Grid>
                <Grid item xs={3} className={classes.alignright}>
                    <Typography color='textSecondary'>Dluh</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
            </Grid>
            {
                debts.map(d => {
                    return (
                        <Grid key={d.id} container>
                            <Grid item xs={6}>
                                <Typography>
                                    {d.description}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} className={classes.aligncenter}>
                                <Typography>
                                    <DisplayValue className={d.dueDebt ? classes.due : null} value={d.dueDate} type='date' />
                                </Typography>
                            </Grid>
                            <Grid item xs={3} className={classes.alignright}>
                                <Typography>
                                    <DisplayValue className={d.dueDebt ? classes.due : null} value={d.debt} type='currency' />
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })
            }
            <Grid container>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                    <Typography component='p' variant='body1' style={{fontStyle: 'italic', textIndent: '8px'}}>
                        Bankovní spojení: 1391984012 / 3030, číslo dresu jako variabilní symbol
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>                
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Button className={classes.marginRight} 
                                size="small" color="secondary" variant= "contained" type="button" startIcon={<EmailIcon />}
                                onClick={handleSend}
                            >
                                Poslat email
                            </Button>
                        </Grid>
                        <Grid item xs={6} className={classes.alignright} >
                            <Button
                                size="small" color="secondary" variant= "contained" type="button" startIcon={<CropFreeIcon />}
                                onClick={handleQR}
                            >
                                QR kód
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SendMailDlg 
                    name="mailDlg"
                    dlgOpen={dlgOpen}
                    setDlgOpen={setDlgOpen}
                    email={email}
                    mailType="debt"
                />
            <QRCodeDlg 
                    name="qrDlg"
                    dlgOpen={qrOpen}
                    setDlgOpen={setQrOpen}
                    //email={email}
                    //mailType="debt"
                />

        </Paper>
    );
}

export default MyDebtCard;
