import React, { useContext, useEffect, useState } from 'react';
import { Button, Divider, FormControl, Grid, IconButton, makeStyles, Paper, Typography } from '@material-ui/core';
import { FormHeader } from '../Infrastructure/PageElements';
import PageLayout from '../Infrastructure/PageLayout';
import { FieldArray, Form, Formik } from 'formik';
import Controls from '../../../controls/Controls';
import * as yup from 'yup';
import moment from "moment";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { GetPeopleList, SavePayments } from './AdminService';
import { GetLOVs } from '../Infrastructure/PublicApi';
import AuthContext from '../../App/AuthContext';
import Notification from '../../App/Notification';

const useStyles = makeStyles((theme) => ({
    header: {
        paddingBottom: theme.spacing(0.5),
    },
    divider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
    },
    filter: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    alignleft: {
        textAlign: 'left',
    },
    alignright: {
        textAlign: 'right',
    },
    margin: {
        margin: theme.spacing(0.5),
        padding: '1px',
      },
}));

function TreasuryGen(props) {
    const classes = useStyles();
    const authCtx = useContext(AuthContext);
    const [paymentTypeLOV, setPaymentTypeLOV] = useState(null);
    const closedNotify = { isOpen: false, message: '', type: '', title: '' };
    const [notify, setNotify] = useState(closedNotify);
    const [apiPeople, setApiPeople] = useState([]);
    let peopleArrProps = null;

    const initialPaymentValues = { amount: null, trtype: '', dueDate: moment(), description: '', people: [], peoplegrp: null};
    const validationSchema = yup.object().shape({
        amount: yup.number().nullable().required('Zadej částku').min(1, 'Minimálně 1 Kč'),
        description: yup.string().required('Zadej popis platby'),
        trtype: yup.string().nullable().required('Zadej typ platby'),
        dueDate: yup.date().nullable().typeError('').required('Zadej datum splatnosti'),
        //people: yup.array().min(1, 'Required'),
    });


    const handleSavePayments = (values, onSubmitProps) => {
        if ((values.trtype !== '3' && values.trtype !== '7' && values.trtype !== '8') || (values.people && values.people.length > 0)) {
            const handle = (res) => {
                if (res && res.errorCode !== -1000) {
                   if (res.errorCode === undefined) {
                       if (res.ResultCode === 1) {
                        setNotify({ isOpen: true, message: 'Platba byla uložena', type: 'success' })
                       } else setNotify({ isOpen: true, message: res.Description, type: 'error' }); 
                   } else 
                       setNotify({ isOpen: true, message: 'Nepodařilo se provést požadovanou operaci ', type: 'error' }); 
               } else {
                   setNotify({ isOpen: true, message: 'Služba není momentálně přístupná', type: 'error' });
               }
               onSubmitProps.setSubmitting(false);
           }
           const data = {
                trtype: values.trtype,
                dueDate: values.dueDate,
                amount: values.amount,
                description: values.description,
                people: values.people.map(item => item.id),
           };
           SavePayments(authCtx.auth.token, data, handle);
        
        } else 
            setNotify({ isOpen: true, message: 'Zadej osoby, pro které se mají uložit platby', type: 'warning' })
    }
    
    const handlePayType = (/*event*/) => {
        if (!paymentTypeLOV) {
            const lovHandler = (res) => {
                if (res && res.errorCode === undefined) {
                    setPaymentTypeLOV(res.paytypes);
                }
            }
            GetLOVs(['paytypes'], lovHandler); 
        }
    }

    const goTreasury = () => {
        props.history.push('/treasury'); 
    }
    
    const peopleGroups = [
        {id: 'AB', title: "...Všichni hráči", category: 'AB'},
        {id: 'A', title: "...Áčko", category: 'A'},
        {id: 'B', title: "...Béčko", category: 'B'},
        {id: 'X', title: "...Příznivci", category: 'X'}
    ];

    const addPeople = () => {
        if (peopleArrProps && peopleArrProps.form.values.peoplegrp) {
            var foundCat = peopleGroups.find(e => e.id === peopleArrProps.form.values.peoplegrp.id);
            if (!!foundCat) {
                apiPeople.forEach((item, index) => {
                    var found = peopleArrProps.form.values.people && peopleArrProps.form.values.people.find(e => e.id === item.id);
                    if (!found && (peopleArrProps.form.values.peoplegrp.category.indexOf(item.category) >= 0)) {
                        peopleArrProps.push(item);
                    }
                }) 
            } else {
                var found = peopleArrProps.form.values.people && peopleArrProps.form.values.people.find(e => e.id === peopleArrProps.form.values.peoplegrp.id);
                if (!found) {
                    peopleArrProps.push(peopleArrProps.form.values.peoplegrp);
                }
            }
        } else setNotify({ isOpen: true, message: 'Vyber skupinu nebo jednotlivou osobu', type: 'warning' });
    }

    const removePeople = () => {
        if (peopleArrProps) {
            peopleArrProps.form.setFieldValue('people', null);
        }        
    }

    useEffect(() => {
        const getPeopleList = () => {
            const handler = (res) => {
                setApiPeople(
                    res.map(item => ({id: item.id, title: item.firstName + ' ' + item.lastName, category: item.member }))
                );
              }
              if (apiPeople && apiPeople.length === 0)
                GetPeopleList(authCtx.auth.token, handler);
        }
        getPeopleList();
    }, [apiPeople,authCtx.auth.token]);

    return (
        apiPeople && apiPeople.length > 0 ?
        <PageLayout width="md">
            <FormHeader className={classes.header} text="Generování plateb" />
            <Paper className={classes.filter}>
                <Formik
                    initialValues={initialPaymentValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSavePayments}
                >{formik => {
                    const profileRequired = paymentTypeLOV 
                                && paymentTypeLOV.find(e => e.id === formik.values.trtype)
                                && paymentTypeLOV.find(e => e.id === formik.values.trtype).adds === 'True';
                    if (!profileRequired) formik.values.dueDate = moment();
                    return (
                    <Form>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={profileRequired ? 5 : 7}>
                                <Controls.DropDownList 
                                    name="trtype" 
                                    size="small" 
                                    //margin="none" 
                                    onOpen={handlePayType}
                                    nullTitle = ""
                                    label="Typ platby*"
                                    color="secondary"
                                    //displayEmpty={true}
                                    options={paymentTypeLOV}
                                />
                            </Grid>
                            <Grid item xs={profileRequired ? 6 : 12} sm={6} md={profileRequired ? 3 : 5}>
                                <Controls.NumberInput
                                    name="amount"
                                    size="small" 
                                    //margin="none"                                    
                                    format="###### Kč"
                                    label="Částka*"
                                    //align='left'
                                />
                            </Grid>
                            {
                                profileRequired &&
                                <Grid item xs={6} sm={6} md={4}>
                                    <Controls.DatePicker 
                                        name="dueDate"
                                        size="small"
                                        //margin="none"
                                        label="Datum splatnosti*"
                                    />
                                </Grid>
                            }
                            
                            <Grid item xs={12} sm={profileRequired ? 6 : 12} md={12}>
                                <Controls.Input
                                    name="description"
                                    size="small"
                                    label="Popis*"
                                />
                            </Grid>
                           
                            { profileRequired && (<>
                                <Grid item xs={12}>
                                    <FormHeader text="Seznam osob" align='left'/>
                                    <FieldArray name="people">
                                        { 
                                        (fieldArrayProps) => {
                                            peopleArrProps = fieldArrayProps;
                                            return (
                                                    <Grid container>
                                                        {fieldArrayProps.form.values.people && fieldArrayProps.form.values.people.length > 0 && (
                                                            fieldArrayProps.form.values.people.map((item, index) => (
                                                                <Grid item key={index} xs={6} sm={4} lg={3}>
                                                                <Typography>
                                                                     <IconButton 
                                                                        className={classes.margin} 
                                                                        onClick={() => peopleArrProps.remove(index)}
                                                                    >
                                                                        <RemoveCircleOutlineIcon fontSize='small' color='secondary' />
                                                                     </IconButton>
                                                                     <span name={item.id}>{item.title}</span>
                                                                </Typography>
                                                                </Grid>
                                                                ))
                                                            )
                                                        }
                                                    </Grid>
                                                )
                                            }
                                        }
                                    </FieldArray>
                                </Grid>
                            </>)}
                            <Grid item xs={12}>
                                <Divider className={classes.divider}/>
                            </Grid>
                            {
                            profileRequired &&
                            <Grid item xs={12} className={classes.alignleft}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={8}>
                                        <Controls.Autocomplete
                                            name="peoplegrp"
                                            size="small"
                                            label="Přidat skupinu / Osobu"
                                            margin="none"
                                            options={peopleGroups.concat(apiPeople)}
                                            //optionFieldName="title"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2} className={classes.alignright}>
                                        <FormControl>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            variant="outlined"
                                            endIcon={<AddCircleOutlineIcon />}
                                            onClick={() => addPeople()}
                                        >
                                            Přidat
                                        </Button>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={2} className={classes.alignright}>
                                        <FormControl>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            variant="outlined"
                                            endIcon={<RemoveCircleOutlineIcon />}
                                            onClick={() => removePeople()}
                                        >
                                            Smazat
                                        </Button>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider className={classes.divider}/>
                                </Grid>                                
                            </Grid>
                            }
                            <Grid item xs={6} className={classes.alignleft}>
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    endIcon={<SaveAltIcon />}
                                    type="submit"
                                >
                                    Uložit platbu
                                </Button>
                            </Grid>
                            <Grid item xs={6} className={classes.alignright}>
                                <Button
                                    size="small"
                                    color="secondary"
                                    variant="text"
                                    margin="none"
                                    startIcon={<ChevronLeftIcon />}
                                    onClick={goTreasury}
                                >
                                    Pokladna
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                    )}
                 }
                </Formik>
            </Paper>
            <Notification notify={notify} setNotify={setNotify} enableClickAway={true} />
        </PageLayout>
        : <></>
    );
}

export default TreasuryGen;