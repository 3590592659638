import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Controls from '../../../controls/Controls';
import { GetProfile, ChangeProfile } from './ProfileService';
import AuthContext from '../../App/AuthContext';
import Notification from '../../App/Notification';
import PageLayout from '../Infrastructure/PageLayout';
import { FormHeader } from '../Infrastructure/PageElements';
import { Grid } from '@material-ui/core';
import ProgressPage from '../Infrastructure/ProgressPage';
import PaperPad from '../Infrastructure/PaperPad';
import { isInRole } from '../../../services/AuthApi';


export default function PersonalData(props) {
    let authCtx = useContext(AuthContext);
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
    const [formValues, setFormValues] = useState(null);
    
    const validationSchema = yup.object().shape({
        firstName: yup.string().required('Zadej jméno'),
        lastName: yup.string().required('Zadej příjmení'),
        birthDate: yup.date().typeError('Neplatné datum').required('Zadej datum narození').nullable(),
        email: yup.string().email('Špatný formát emailové adresy').required('Zadej emailovou adresu').nullable(),
        phone: yup.string().min(12, "Předvolba (např. 420) a 9 číslic").max(12, "Předvolba (např .420) a 9 číslic")
        /*matches(new RegExp('[0-9]{12}'), "Telefon musí být ve formátu (999) 999 999 999").*/
    });
    
    useEffect(() => {
        const handle = (res) => {
            if (res) {
                if (res.errorCode === undefined)
                    setFormValues(res);
                else props.history.push({pathname: '/noaccess', state: res});
            } else {
                props.history.push({pathname: '/noaccess', state: {errorCode: -1000, message: ''}});
            }
        }
        GetProfile(authCtx.auth.token, handle)
    }, [authCtx.auth.token,props.history])

    const onSubmit = (values, onSubmitProps) => {
        const handler = (res) => {
            if (res) {
                if (res.errorCode === undefined) {
                    setNotify({isOpen: true, message: res.Description, type: res.ResultCode === 1 ? 'success' : "error"});
                } else props.history.push({pathname: '/noaccess', state: res});
            } else props.history.push({pathname: '/noaccess', state: {errorCode: -1000, message: ''}});
            onSubmitProps.setSubmitting(false);            
        }
        ChangeProfile(values, authCtx.auth.token, handler);
    }


    return (formValues !== null ? 
        <PageLayout width="lg">
            <FormHeader text="Osobní údaje" />
            <PaperPad width='lg'>
            <Formik
                initialValues={formValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {
                    formik => {
                        return (
                            <Form>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Controls.Input
                                            name="firstName"
                                            label="Jméno*"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Controls.Input
                                            name="lastName"
                                            label="Příjmení*"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <Controls.Input
                                            name="title"
                                            label="Titul"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2} md={2}>
                                        {isInRole(authCtx.auth, 'Player') && <Controls.NumberInput
                                                name="jerseyNumber"
                                                label="Číslo dresu"
                                                format="##"
                                        />}
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Controls.Input
                                            name="email"
                                            label="Email*"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Controls.DatePicker
                                            name="birthDate"
                                            label="Datum narození*"
                                        >
                                        </Controls.DatePicker>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={2}>
                                        <Controls.NumberInput
                                            name="height"
                                            label="Výška"
                                            format="### cm"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={2}>
                                        <Controls.NumberInput
                                            name="weight"
                                            label="Váha"
                                            format="### kg"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Controls.NumberInput
                                            name="phone"
                                            label="Telefon"
                                            format="+### ### ### ###"
                                            align='left'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <Controls.NumberInput
                                            name="zipCode"
                                            label="PSČ"
                                            format="### ##"
                                            align='left'
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Controls.Input
                                            name="address"
                                            label="Ulice č.p."
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Controls.Input
                                            name="city"
                                            label="Město"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Controls.Button fullWidth type="submit"
                                            disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                            text="Uložit"/>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                }}
            </Formik>
            <Notification notify={notify} setNotify={setNotify} />
            </PaperPad>
        </PageLayout>
     : <ProgressPage />);
}
