import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../App/AuthContext';
import { Dialog, DialogActions, DialogContent, Grid, makeStyles } from '@material-ui/core';
import Controls from '../../../controls/Controls';
import { GetPaymentQR } from '../Dashboard/DashboardService';

const useStyles = makeStyles(() => ({
    aligncenter: {
        textAlign: 'center',
    },
    responsiveImg: {
        width: "100%",
        /* maxWidth: "300px", */
        height: "auto"
    },    
}));

function QRCodeDlg(props) {
    let authCtx = useContext(AuthContext);
    const classes = useStyles();
    const { name, dlgOpen, setDlgOpen } = props;
    const [qrCode, setQrCode] = useState(false);

    useEffect(() => {
        const handle = (res) => {
            if (res)
                setQrCode(res);
            else setQrCode(" QR kód se nepodařilo se vygenerovat"); 
        }
        GetPaymentQR(authCtx.auth.token, handle);
    }, [authCtx.auth.token]);

    const handleClose = () => {
        setDlgOpen(false);
    };

     function SvgQr(props) {
        const { image } = props;
        return (<img className={classes.responsiveImg} src={`data:image/svg+xml;base64,${btoa(image)}`} alt="" />);
    }

    return (
        qrCode && <Dialog id={name}
            open={dlgOpen}
            onClose={handleClose}
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth='xs'
        >
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} className={classes.aligncenter}>
                        <SvgQr image={qrCode} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Controls.Button onClick={handleClose} color="secondary" variant="outlined" size="small"
                    text="Zpět"
                />
            </DialogActions>
        </Dialog>
    )
}

export default QRCodeDlg;