import React from 'react';
import { Avatar, FormHelperText, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: '1rem'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
}));

export const FormHeader = (props) => {
    const { text, variant = "body2", align='center', ...other } = props;
    return (
        <Typography
            align={align}
            variant={variant}
            color="secondary"
            {...other}
        >
            {text}
        </Typography>
    );
}

export const IconHeader = (props) => {
    const classes = useStyles();
    const { text, ...other } = props;
    return (
        <div className={classes.paper}>
            <Avatar className={classes.avatar}>
                {props.children}
            </Avatar>
            <Typography
                component="h1"
                variant="h5"
                {...other}
            >
                {text}
            </Typography>
        </div>
    );
}

export const FormErrorMessage = (props) => {
    const classes = useStyles();
    const { message, ...other } = props;
    return (
        <FormHelperText className={classes.root} component="div" error={Boolean(message)} {...other}>{message}</FormHelperText>
    )
}
