import React, { useContext, useState } from 'react';
import AuthContext from '../../App/AuthContext';
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import Notification from '../../App/Notification';
import { Form, Formik } from 'formik';
import Controls from '../../../controls/Controls';
import * as yup from 'yup';
import { SendPaymentMail } from './../Dashboard/DashboardService'

function SendMailDlg(props) {
    let authCtx = useContext(AuthContext);
    const closedNotify = { isOpen: false, message: '', type: '', title: '' };
    const { email, mailType, name, dlgOpen, setDlgOpen } = props;
    const [notify, setNotify] = useState(closedNotify);

    const handleSubmit = (values, onSubmitProps) => {
        const handle = (res) => {
            if (res && res.errorCode !== -1000) {
                if (res.errorCode === undefined) {
                    if (res.ResultCode === 1) {
                        setNotify({ isOpen: true, message: "Koukni se do mailu, brzy tam přijde nová zpráva.", type: 'success' });
                    } else setNotify({ isOpen: true, message: res.Description, type: 'error' });
                } else
                    setNotify({ isOpen: true, message: 'Nepodařilo se provést požadovanou operaci ', type: 'error' });
            } else {
                setNotify({ isOpen: true, message: 'Služba není momentálně přístupná', type: 'error' });
            }
            onSubmitProps.setSubmitting(false);
        }
        SendPaymentMail(authCtx.auth.token, values.email, mailType, handle);
    };

    const handleClose = () => {
        setDlgOpen(false);
    };

    const initialValues = { email: email }

    const validationSchema = yup.object().shape({
        email: yup.string().email('Špatný formát emailové adresy').required('Zadej emailovou adresu')
    });

    return (
        <><Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {
                formik => {
                    return (
                        <Dialog id={name}
                            open={dlgOpen}
                            onClose={handleClose}
                            disableBackdropClick={true}
                            fullWidth={true}
                            maxWidth='xs'
                        >
                            <Form>
                                <DialogContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Controls.Input
                                                name="email"
                                                label="Email*"
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Controls.Button onClick={handleClose} color="secondary" variant="outlined" size="small"
                                        text="Zpět"
                                        disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                    />
                                    <Controls.Button
                                        color="primary" variant="contained" size="small"
                                        text="Poslat platební údaje"
                                        type="submit"
                                        disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                    />
                                </DialogActions>
                            </Form>
                        </Dialog>
                    )
                }
            }
        </Formik>
            <Notification notify={notify} setNotify={setNotify} enableClickAway={true} />
        </>
    )
}

export default SendMailDlg;