import React, { useContext, useEffect, useState } from 'react';
//import ReactDOM from 'react-dom';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Notification from '../../App/Notification';
import { Grid, TextField, Typography } from '@material-ui/core';
import PageLayout from '../Infrastructure/PageLayout';
import { FormHeader } from '../Infrastructure/PageElements';
import PaperPad from '../Infrastructure/PaperPad';
import Controls from '../../../controls/Controls';
import AuthContext from '../../App/AuthContext';
import { GetUserProfile, SaveUserProfile } from '../Admin/AdminService';
import { Autocomplete } from '@material-ui/lab';

const Register = (props) => {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const authCtx = useContext(AuthContext);
  const [formValues, setFormValues] = useState(null);
  const [people, setPeople] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [users, setUsers] = useState(null);
  const [roles, setRoles] = useState([]);

  const validationSchema = yup.object().shape({
    firstName: yup.string().required('Zadej jméno'),
    lastName: yup.string().required('Zadej příjmení'),
    birthDate: yup.date().typeError('Neplatné datum').required('Zadej datum narození').nullable(),
    email: yup.string().email('Špatný formát emailové adresy').required('Zadej emailovou adresu').nullable(),
    userName: yup.string().required('Zadej přihlašovací jméno'),
    roles: yup.array().when('active', { is: (val) => { return val }, then : yup.array().min(1, 'Přiřaď alespoň jednu roli') }),
  });

  useEffect(() => {
    const handle = (res) => {
      if (res) {
        if (res.errorCode === undefined) {
          setFormValues({ 
            id: res.id, 
            firstName: res.firstName,
            lastName: res.lastName,
            birthDate: res.birthDate,
            email: res.email,
            userName: res.userName,
            active: res.active,
            roles: res.roles != null ? res.roles.filter(x => (x.assigned)).map(item => (item.id)) : []/* res.roles */ });
          setRoles(res.roles != null ? res.roles.map(item => ({key: item.id, label: item.name })): []);

          setUsers(res.users.map(item => ({ id: item.id, title: item.firstName + ' ' + item.lastName + ' - ' + item.member })))
          var userItem = res.users.find(e => e.id === res.id);
          setPeople(userItem ? { id: userItem.id, title: userItem.firstName + ' ' + userItem.lastName + ' - ' + userItem.member }: null);
        }
        else props.history.push({ pathname: '/noaccess', state: res });
      } else
        props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
    }
    GetUserProfile(authCtx.auth.token, people ? people.id : null, handle)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCtx.auth.token, props.history, refresh])

  const onSubmit = (values, onSubmitProps) => {
    const handler = (res) => {
      if (res) {
        if (res.errorCode === undefined) {
          if (res.ResultCode === 1) { 
            onFindser(null, {id: res.Id, title: "nový"}, null)
          }
          setNotify({ isOpen: true, message: res.Description, type: res.ResultCode === 1 ? 'success' : "error" });
        } else props.history.push({ pathname: '/noaccess', state: res });
      } else props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
      onSubmitProps.setSubmitting(false);
    }

    var data = {
      id: values.id,
      firstName: values.firstName,
      lastName: values.lastName,
      birthDate: values.birthDate,
      email: values.email,
      active: values.active,
      userName: values.userName,
      roles: values.roles.map(item => ({id: item, name:'', assigned: true}))
    }
    SaveUserProfile(authCtx.auth.token, data, handler);
  }

  const onFindser = (e, value, reason) => {
    setFormValues(null);
    setPeople(value);
    setRefresh(!refresh);
  }

  return (
    <PageLayout width='lg'>
      <FormHeader text="Uživatelé" />
      <PaperPad width='lg'>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {users && <Autocomplete
              id="userList"
              options={users}
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option) => option.id}
              size='small'
              fullWidth
              value={people}
              renderInput={(params) => <TextField {...params} label="Najít uživatele" color='secondary' variant="outlined" />}
              onChange={onFindser}
            />}
          </Grid>
        </Grid>
        {formValues && users &&
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {formik => {
              return (
                <Form>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant='body2' color='secondary'>{`${formik.values.id ? "Id: " + formik.values.id : "nový uživatel"}`}{}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="firstName"
                        label="Jméno*"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="lastName"
                        label="Příjmení*"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.DatePicker
                        name="birthDate"
                        label="Datum narození*"
                        size="small"
                      >
                      </Controls.DatePicker>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="email"
                        label="Email*"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {roles && 
                        <Controls.CheckBoxGroup 
                          label="Oprávnění*" 
                          name="roles" 
                          color="secondary"
                          size="small"
                          options={roles}
                        />
                      }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Input
                        name="userName"
                        label="Přihlašovací jméno*"
                        color="secondary"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controls.Checkbox
                        name="active"
                        label="Aktivní"
                        color="secondary"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Controls.Button fullWidth type="submit"
                        disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                        text="Uložit" />
                    </Grid>
                  </Grid>
                </Form>)
            }}
          </Formik>
        }
        <Notification notify={notify} setNotify={setNotify} />
      </PaperPad>
    </PageLayout>
  );
}

export default Register;