import { Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import DisplayValue from './DisplayValue';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
/*         '& .MuiCardHeader-root': {
            padding: theme.spacing(0, 0, 0.5, 0),
            '& .MuiCardHeader-content': {
                '& .MuiCardHeader-title': {
                    fontSize: "1rem",
                }

            }
        },
 */        '& .MuiCardContent-root': {
            padding: theme.spacing(0),
        }
    },
    header: {
        paddingTop: theme.spacing(1),
        fontSize: '1.2em',
        paddingBottom: theme.spacing(0.5),
    },
    itemA: {
        textAlign: 'center',
        marginLeft: 'auto',
        color: theme.palette.color5.main,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5)
    },
    itemB: {
        textAlign: 'center',
        marginLeft: 'auto',
        color: theme.palette.color6.main,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5)
    },
    item: {
        textAlign: 'center',
        marginLeft: 'auto',
        color: theme.palette.text.secondary,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5)
    },
    photo: {
        paddingRight: theme.spacing(1)
    },
    image: {
        height: 'auto',
        width: '65px',
        verticalAlign: 'middle'
    },
}));

function ProfileCard(props) {
    const { data, ...other } = props;
    const classes = useStyles();
    const players = require.context('../../../../public/Players', true);
    const photo = players(`./${data.id}.jpg`);
    return (
        <Card key={data.id} {...other} variant="outlined" className={classes.root}>
            <div>
                <CardContent>
                    <Grid container>
                        <Grid item className={classes.photo}>
                            <img className={classes.image} src={photo.default} alt='' />
                        </Grid>
                        <Grid item>
                            <div className={classes.header}>{`${data.title} ${data.firstName} ${data.lastName}`}</div>
                            <div>{data.email}</div>
                            <div><DisplayValue value={data.phone} type="phone" /></div>
                        </Grid>
                        <Grid item className={data.member === 'A' ? classes.itemA : data.member === 'B' ? classes.itemB : classes.item}>
                            <div>{data.member}</div>
                            <div>{data.jerseyNumber}</div>
                        </Grid>
                    </Grid>
                </CardContent>
            </div>
        </Card>
    );
}

export default ProfileCard;