import { Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import DisplayValue from './DisplayValue';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        '& .MuiCardContent-root': {
            padding: theme.spacing(0),
        }
    },
    header: {
        paddingTop: theme.spacing(1),
        /* fontSize: '1.1em', */
        paddingBottom: theme.spacing(0.5),
    },
    item: {
        textAlign: 'center',
        marginLeft: 'auto',
        /* color: theme.palette.color5.main, */
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5)
    },
    value: {
        fontSize: '1.2em',
        color: theme.palette.secondary.main,
        /* textAlign: 'center',
        width: '100%', */
    },
    small: {
        paddingTop: theme.spacing(1),
        fontSize: "0.85em",
        fontStyle: "italic",
        color: theme.palette.text.secondary,
    },
    photo: {
        paddingRight: theme.spacing(3)
    },
    image: {
        height: 'auto',
        width: '45px',
        verticalAlign: 'middle'
    },
}));

function PerformCard(props) {
    const { data, index, type="integer", ...other } = props;
    const classes = useStyles();
    const players = require.context('../../../../public/Players', true);
    const photo = players(`./${data.peopleId}.jpg`);
    return (
        <Card {...other} variant="outlined" className={classes.root}>
            <div>
            <CardContent>
                    <Grid container>
                        <Grid item className={classes.photo}>
                            <img className={classes.image} src={photo.default} alt='' />
                        </Grid>                        
                        <Grid item>
                            <div className={classes.header}>{index}. {data.peopleName}</div>
                            <div className={classes.value}>
                                <DisplayValue value={data.value} type={type} decimals={2} />
                            </div>
                        </Grid>
                    </Grid>
                    {data.date && <div className={classes.small}><DisplayValue value={data.date} type="date"/></div>}
                    <div className={classes.small}>{data.description}</div>
                </CardContent>
            </div>
        </Card>
    );
}

export default PerformCard;