import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../AppPages/Dashboard/Dashboard';
import ErrorPage from './ErrorPage';
import LoginPage from '../AppPages/Account/LoginPage';
import Register from '../AppPages/Account/Register';
import ForgottenPassword from '../AppPages/Account/ForgottenPassword';
import ResetPassword from '../AppPages/Account/ResetPassword';
import PersonalData from '../AppPages/Profile/PersonalData';
import UserSettings from '../AppPages/Dashboard/UserSettings';
import AppInfo from '../AppPages/Admin/AppInfo';
import Logging from '../AppPages/Admin/Logging';
import Galery from '../AppPages/Dashboard/Galery';
import CashPayments from '../AppPages/Dashboard/CashPayments';
import Treasury from '../AppPages/Admin/Treasury';
import TreasuryGen from '../AppPages/Admin/TreasuryGen';
import TreasuryBal from '../AppPages/Admin/TreasuryBal';
import Schedule from '../AppPages/Dashboard/Schedule';
import Recruitment from '../AppPages/Dashboard/Recruitment';
import Tables from '../AppPages/Dashboard/Tables';
import Archive from '../AppPages/Dashboard/Archive';
import Stats from '../AppPages/Admin/Stats';
import Season from '../AppPages/Admin/Season';
import BestPerformance from '../AppPages/Dashboard/BestPerformance';


export function RouterSwitch(props) {
  return (
    <Switch>
      <Route exact path="/" component={Dashboard}></Route>
      <Route path="/schedule" component={Schedule}></Route>
      <Route path="/galery" component={Galery}></Route>
      <Route exact path="/archive" component={Archive}></Route>
      <Route path="/archive/top" component={BestPerformance}></Route>
      <Route path="/cash" component={CashPayments}></Route>
      <Route exact path="/treasury" component={Treasury}></Route>
      <Route path="/treasury/genpay" component={TreasuryGen}></Route>
      <Route path="/treasury/balance" component={TreasuryBal}></Route>
      <Route path="/recruit" component={Recruitment}></Route>
      <Route path="/table" component={Tables}></Route>
      <Route path="/login" component={LoginPage}></Route>
      <Route path="/forgotten" component={ForgottenPassword}></Route>
      <Route path="/profile" component={PersonalData}></Route>
      <Route path="/settings" component={UserSettings}></Route>
      <Route exact path="/appinfo" component={AppInfo}></Route>
      <Route path="/appinfo/register" component={Register}></Route>
      <Route path="/appinfo/season" component={Season}></Route>
      <Route path="/appinfo/logs" component={Logging}></Route>
      <Route path="/resetpsw/:id" component={ResetPassword}></Route>
      <Route path="/stats" component={Stats}></Route>
      <Route path="/noaccess" component={ErrorPage}></Route>
      <Route component={ErrorPage}></Route>
    </Switch>
  );
}
