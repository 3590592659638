const apiUrl = process.env.REACT_APP_WEBAPIURL

export const isInRole = (authModel, role) => {
  return authModel && authModel.isAuthenticated && authModel.roles && authModel.roles.indexOf(role) >= 0;
}

export const GetAuthModel = () => {
    return {
      isAuthenticated: false, token: '', name: '', lastName: '', firstName: '', roles: []
    };
}

const RefreshToken = (refreshToken, callback) => {
  fetch(`${apiUrl}Authenticate`, {
    method: 'POST',
    headers: { 'Accept': 'application/json', 'Content-type': 'application/x-www-form-urlencoded' },
    body: 'refresh_token=' + refreshToken + '&grant_type=refresh_token'
  }).then(r => r.json()).then(res => {
      callback(res);
  }).catch((/* error */) => {
    //console.log(error);
    callback(null);
  });
}

const GetToken = (userName, password, callback) => {
    fetch(`${apiUrl}Authenticate`, {
      method: 'POST',
      headers: { 'Accept': 'application/json', 'Content-type': 'application/x-www-form-urlencoded' },
      body: 'username=' + userName + '&password=' + password + '&grant_type=password'
    }).then(r => r.json()).then(res => {
        callback(res);
    }).catch((/* error */) => {
      //console.log(error);
      callback(null);
    });
}
  
const GetUser = (token, callback) => {
    let user = GetAuthModel();
    fetch(`${apiUrl}api/Account`, {
      method: 'GET',
      headers: { 'Content-type': 'application/json', 'Authorization': 'Bearer ' + token },
      body: undefined
    }).then(r => r.json()).then(res => {
      if (res && res.Id && res.Id !== '') {
        user.lastName = res.LastName;
        user.firstName = res.FirstName;
        user.name = res.UserName;
        user.roles = res.Roles;
        user.token = token;
        user.isAuthenticated = true;
      }
      callback(user);
    }).catch((/*error*/) => {
      //console.log(error);
      callback(user);
    });    
}

const getUserHandler = (res, callback) => {
  let newRefreshToken = '';

  const handleGetUser = (res) => {
    callback(res, newRefreshToken)
  }

  if (res) {
    if (res.access_token) {
      newRefreshToken = res.refresh_token;
      GetUser(res.access_token, handleGetUser);
    } else {
      callback(GetAuthModel(), '');
    }
  } else {
    callback(null);
  }
}

export const GetUserByRefreshToken = (refreshToken, callback) => {
  const handleGetUser = (res) => {
    getUserHandler(res, callback);
  }

  RefreshToken(refreshToken, handleGetUser);
}

export const GetUserByPassword = (userName, password, callback) => {
  const handleGetUser = (res) => {
    getUserHandler(res, callback);
  }

  GetToken(userName, password, handleGetUser);
}  