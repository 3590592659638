import { Dialog, DialogContent, DialogActions, Grid, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import React, { useContext, useEffect, useState } from 'react';
import Controls from '../../../controls/Controls';
import AuthContext from '../../App/AuthContext';
import Notification from '../../App/Notification';
import { SaveScheduleData } from './AdminService';
import { GetLOVs } from '../Infrastructure/PublicApi';

function ScheduleDlg(props) {
    const { name, event = null, setEvent, refreshHandler } = props;
    const [LOVs, setLOVs] = useState(null);

    const authCtx = useContext(AuthContext);
    const closedNotify = { isOpen: false, message: '', type: '', title: '' };

    const [notify, setNotify] = useState(closedNotify);

    const initialValues = event ? {
        id: event.id, eventType: event.eventType, eventDate: event.eventDate,
        address: event.address ?? '', city: event.city ?? '', description: event.description ?? '',
        team: event.team ?? ''
        }
        : { id: -1, eventType: '', eventDate: null, address: '', city: '', description: '', team: '' };

    const validationSchema = yup.object().shape({
        eventDate: yup.date().typeError('Neplatné datum').required('Zadej datum a čas').nullable(),
        eventType: yup.string().nullable().required('Zadej typ akce'),
        address: yup.string().when('city', { is: (val) => { return !val || val === '' }, then: yup.string().required('Zadej adresu nebo město') }),
        description: yup.string().when('eventType', { is: (val) => { return val === '4' }, then: yup.string().required('Zadej popis akce') }),
    });

    useEffect(() => {
        const handler = (res) => {
            setLOVs(res);
        }
        GetLOVs(['actiontypes', 'teams'], handler);
    }, [authCtx.auth.token]);

    const handleClose = () => {
        setNotify(closedNotify);
        setEvent(null);
    };

    const changeHandler = (res) => {
        if (res && res.errorCode !== -1000) {
            if (res.errorCode === undefined) {
                if (res.ResultCode === 1) {
                    refreshHandler(res);
                    handleClose();
                } else setNotify({ isOpen: true, message: res.Description, type: 'error' });
            } else
                setNotify({ isOpen: true, message: 'Nepodařilo se provést požadovanou operaci ', type: 'error' });
        } else {
            setNotify({ isOpen: true, message: 'Služba není momentálně přístupná', type: 'error' });
        }
    }

    const handleSubmit = (values, onSubmitProps) => {
        const handle = (res) => {
            changeHandler(res);
            onSubmitProps.setSubmitting(false);
        }
        SaveScheduleData(authCtx.auth.token, values, handle)
    }

    return (
        event && LOVs && <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >{formik => {
                return (
                    <Dialog
                        id={name}
                        open={event !== null}
                        onClose={handleClose}
                        disableBackdropClick={true}
                        fullWidth={true}
                    >
                        <Form style={{ width: '100%' }}>
                            <DialogContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6}>
                                        {!event.automated ? 
                                            <Controls.DropDownList name="eventType" size="small" margin="dense" label="Typ akce"
                                                nullTitle=""
                                                options={LOVs.actiontypes}
                                            /> : <>
                                            <Typography variant='body1' color='secondary'>
                                                {event.eventDescription}
                                            </Typography>
                                            <Typography variant='body1'>
                                                {event.description}
                                            </Typography>                                            
                                            </>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controls.DateTimePicker
                                            name="eventDate"
                                            label="Datum a čas"
                                            size="small"
                                            margin="dense"
                                        //disablePast={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controls.Input
                                            name="address"
                                            label="Adresa"
                                            size="small"
                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Controls.Input
                                            name="city"
                                            label="Město"
                                            size="small"
                                            margin="dense"
                                        />
                                    </Grid>
                                    {!event.automated &&
                                    <Grid item xs={12} sm={8}>
                                        <Controls.Input
                                            name="description"
                                            label="Poznámka"
                                            size="small"
                                            margin="dense"
                                        /> 
                                    </Grid>}
                                    {!event.automated &&
                                    <Grid item xs={12} sm={4}>
                                        <Controls.DropDownList name="team" size="small" margin="dense" label="Tým"
                                            nullTitle="...všichni"
                                            options={LOVs.teams}
                                        />
                                    </Grid>
                                    }
                                    {event.id !== -1 &&
                                    <Grid item xs={12}>
                                        <Typography variant='body2' color='textSecondary'>
                                            {event.automated 
                                              ? "Po úpravě adresy nebo data o více než 15 minut se smažou stávající přihlášení a omluvy."
                                              : "Po úpravě data o více než 15 minut se smažou stávající přihlášení a omluvy."
                                            }
                                        </Typography>
                                    </Grid>
                                    }
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Controls.Button
                                    size="small"
                                    color="secondary"
                                    variant="outlined"
                                    type="button"
                                    onClick={() => { handleClose(); }}
                                    text="Zpět"
                                    disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                />
                                <Controls.Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    text="Uložit"
                                    disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                    />
                            </DialogActions>
                        </Form>
                    </Dialog>
                )
            }}
            </Formik>
            <Notification notify={notify} setNotify={setNotify} enableClickAway={true} />
        </>
    );
}

export default ScheduleDlg;