import React, { /* useContext, */ useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle/* , Grid  */} from '@material-ui/core';
import Controls from '../../../controls/Controls';
import LockIcon from '@material-ui/icons/Lock';

function ConfirmDlg(props) {
    //const closedNotify = { isOpen: false, message: '', type: '', title: '' };
    const { title = "Potrvdit činnost", message = null, okLabel = "OK", disabled = false, parentHandler = null } = props;
    const [open, setOpen] = useState(false);

    const handleSubmit = () => {
        if (parentHandler != null)
        {
            setOpen(false);
            parentHandler();
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
        <Controls.Button type="button"
        size="small"
        color="secondary"
        variant= "outlined"
        text="Ukončit"
        endIcon={<LockIcon />}
        onClick={handleOpen}
        disabled={disabled}
        />

        <Dialog
            open={open}
            onClose= {handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            {message && <DialogContent>

            </DialogContent>}
            <DialogActions>
                <Controls.Button onClick={handleClose} color="secondary" variant="outlined" size="small"
                    text="Zpět"
                />
                <Controls.Button
                    color="primary" onClick={handleSubmit} variant="contained" size="small"
                    text={okLabel}
                />
            </DialogActions>
        </Dialog>
        </>
    )
}

export default ConfirmDlg;