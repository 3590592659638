import { Grid, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../App/AuthContext';
import { FormHeader } from '../Infrastructure/PageElements';
import PageLayout from '../Infrastructure/PageLayout';
import ProgressPage from '../Infrastructure/ProgressPage';
import ProfileCard from '../Partials/ProfileCard';
import { GetGaleryList } from './DashboardService';

function Galery(props) {
    const authCtx = useContext(AuthContext);
    const [peopleList, setPeopleList] = useState(null);
    const [value, setValue] = useState('all');

    useEffect(() => {
        const handle = (res) => {
            if (res) {
                if (res.errorCode === undefined)
                    setPeopleList(res);
                else props.history.push({ pathname: '/noaccess', state: res });
            } else {
                props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
            }
        }
        GetGaleryList(authCtx.auth.token, handle)
    }, [authCtx.auth.token, props.history])

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const getList = (filter) => {
        switch (filter){
            case "all": return peopleList;
            case "fan": return peopleList.filter(f => f.member === '');
            default: return peopleList.filter(f => f.member === filter);
        }
    }
    return (
        peopleList !== null ?
            (<PageLayout width="lg">
                <FormHeader text="Galerie" />
                <div>
                    <RadioGroup row name="teamList" value={value} onChange={handleChange}>
                        <FormControlLabel value="all" control={<Radio size="small"/>} label="Všichni" />
                        <FormControlLabel value="A" control={<Radio size="small"/>} label="A" />
                        <FormControlLabel value="B" control={<Radio size="small"/>} label="B" />
                        <FormControlLabel value="fan"  control={<Radio size="small"/>} label="Příznivci" />
                    </RadioGroup>
                </div>
                <Grid container spacing={1}>
                    {
                        getList(value).map(item => {
                            return (
                                <Grid key={item.id} item xs={12} sm={12} md={6} lg={4} >
                                    <ProfileCard data={item}></ProfileCard>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </PageLayout>) : (<ProgressPage />)
    );
}

export default Galery;