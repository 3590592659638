const apiUrl = process.env.REACT_APP_WEBAPIURL

const JsonToUrlParams = (params) => {
  return params ? encodeURI('?' + Object.keys(params).map(key => key + '=' + params[key]).join('&')) : '';
}

const callWebApi = (api, method, data, token, handler) => {
  let header = { 'Content-type': 'application/json' }
  if (token && token !== '') {
    header.Authorization = 'Bearer ' + token
  }
  let url = `${apiUrl}api/${api}`;
  let body = null;
  let httpMethod = method.toUpperCase();
  if (httpMethod === 'GET' || httpMethod === 'DELETE') {
    url = url + JsonToUrlParams(data);
  } else body = data;

  fetch(url, {
    method: httpMethod,
    headers: header,
    body: body !== undefined ? body === null ? JSON.stringify() : JSON.stringify(body) : body
  }).then(r => r.json()).then(res => {
    if (handler) handler(res);
  }).catch((/*error*/) => {
    //console.log(error); // TEMPORARY
    if (handler) handler(null);
  });
  return null;
}

export const JsonToFilter = (filter) => {
  return JSON.stringify(filter);
}

export const callWebApiAuth = (api, method, data, token, handler) => {
  if (token && token !== '') {
    callWebApi(api, method, data, token, handler);
  } else handler({ errorCode: -1, message: 'Pokus o neautorizovaný přístup' });
}

export const callWebApiAnonymous = (api, method, data, handler) => {
  callWebApi(api, method, data, null, handler);
}

export const callHandleResult = (res, handler) => {
  if (handler) {
    if (!res) handler({ errorCode: -1000, message: 'Cannot access the web service' });
    else if (res.SysMessage) handler({ errorCode: -1001, message: res.SysMessage });
    else if (res.Message) handler({ errorCode: -2, message: res.Message });
    else handler(res);
  }
}
