import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../App/AuthContext';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PageLayout from '../Infrastructure/PageLayout';
import ProgressPage from '../Infrastructure/ProgressPage';
import { GetTopPerformance } from './DashboardService';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Box, Button, Grid } from '@material-ui/core';
import { FormHeader } from '../Infrastructure/PageElements';
import PerformCard from '../Partials/PerformCard';
import MyPerformance from '../Partials/MyPerformance';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    /* minHeight: 100, */
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'inherit',
    minWidth: '100px',
/*     '& .MuiCardContent-root': {
      color: 'red',
  }
 */  },
  tabbtn: {
    minWidth: '100px',
  },
  content: {
    padding: theme.spacing(0.5),
    /* display: 'flex', */
  },
  width100: {
    width: '100%',
  }
}));

function TabPanel(props) {
  const { value, index, data, type = "integer", ...other } = props;
  const classes = useStyles();

  return (data &&
    <div className={classes.content}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Grid container spacing={1}>
            {data.map((d, ix) => {
              return (
                <Grid key={ix} item xs={12} sm={12} md={6}>
                  <PerformCard data={d} index={ix + 1} type={type} />
                </Grid>)
            })}
          </Grid>
        </Box>
      )}
    </div>
  );
}

function TabCard(props) {
  const { value, index, data, ...other } = props;
  const classes = useStyles();

  return (data &&
    <div className={classes.content}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Grid className={classes.width100} container spacing={1}>
            <Grid item xs={12}>
              <MyPerformance data={data} />
            </Grid>
          </Grid>
        </Box>
      )}
    </div>

  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
  };
}

export default function BestPerformance(props) {
  const classes = useStyles();
  const authCtx = useContext(AuthContext);
  //const [onRefreshChange, setOnRefreshChange] = useState(false);
  const [value, setValue] = useState(0);
  const [apiData, setApiData] = useState(null);
  const [tabText, setTabText] = useState("Moje nejlepší");

  /* const refreshHandler = () => {
    setOnRefreshChange(!onRefreshChange);
  }
*/
  useEffect(() => {
    const handler = (res) => {
      if (res && res.errorCode === undefined) {
        setApiData(res);
      }
      else props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
    }
    GetTopPerformance(authCtx.auth.token, handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCtx.auth.token /*, onRefreshChange*/]);

  const getHeaderLabel = (ix) => {
    switch (ix) {
      case 0: return "Moje nejlepší";
      case 1: return "Nejvíce bodů v jednom zápase";
      case 2: return "Nejvíce šestek v jednom zápase";
      case 3: return "Nejvíce trojek v jednom zápase";
      case 4: return "Celkem nastřílených bodů";
      case 5: return "Celkem odehraných zápasů";
      case 6: return "Celkové procento šestek";
      case 7: return "Celkem nastřílených trojek";
      case 8: return "Průměr faulů na zápas";
      default: return "";
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTabText(getHeaderLabel(newValue));
  };

  const goArchive = () => {
    props.history.push('/archive');
  }

  return (
    apiData ?
      (<PageLayout width="md">
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Button
              size="small"
              color="secondary"
              variant="text"
              margin="none"
              startIcon={<ChevronLeftIcon />}
              onClick={goArchive}
            >
              Archiv
            </Button>
          </Grid>
          <Grid item xs={8}>
            <FormHeader text={tabText} align='left' />
          </Grid>
        </Grid>
        <div className={classes.root}>
          <Tabs
            orientation="vertical"
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            className={classes.tabs}
          >
            <Tab className={classes.tabbtn} label="Moje" {...a11yProps(0)} />
            <Tab className={classes.tabbtn} label="Body" {...a11yProps(1)} />
            <Tab className={classes.tabbtn} label="Šestky" {...a11yProps(2)} />
            <Tab className={classes.tabbtn} label="Trojky" {...a11yProps(3)} />
            <Tab className={classes.tabbtn} label="Body Σ" {...a11yProps(4)} />
            <Tab className={classes.tabbtn} label="Zápasy Σ" {...a11yProps(5)} />
            <Tab className={classes.tabbtn} label="Šestky %" {...a11yProps(6)} />
            <Tab className={classes.tabbtn} label="Trojky Σ" {...a11yProps(7)} />
            <Tab className={classes.tabbtn} label="Fauly Ø" {...a11yProps(8)} />
          </Tabs>
          <div className={classes.width100}>
            <TabCard value={value} index={0} data={apiData.myBest} />
            <TabPanel value={value} index={1} data={apiData.bestPoints} />
            <TabPanel value={value} index={2} data={apiData.bestFreeThrows} />
            <TabPanel value={value} index={3} data={apiData.bestThreePoints} />
            <TabPanel value={value} index={4} data={apiData.sumPoints} />
            <TabPanel value={value} index={5} data={apiData.sumGames} />
            <TabPanel value={value} index={6} data={apiData.procFreeThrows} type="decimal" />
            <TabPanel value={value} index={7} data={apiData.sumThreePoints} />
            <TabPanel value={value} index={8} data={apiData.avgFouls} type="decimal" />
          </div>
        </div>
      </PageLayout>) : (<ProgressPage />)
  );
}
