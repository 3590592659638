//import './App.css';
import { createMuiTheme, ThemeProvider, CssBaseline } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { GetAuthModel, GetUserByRefreshToken } from '../../services/AuthApi';
import AuthContext from "./AuthContext";
import AppMenu from './AppMenu';
import Cookies from 'js-cookie';

const themeLight = createMuiTheme({
  mixins: {
    toolbar: {
        minHeight: 48,
        "@media (min-width:0px) and (orientation: landscape)": {
            minHeight: 48
        },
        "@media (min-width:600px)": {
            minHeight: 48
        }
    }
  },
  palette: {
    type: 'light',
      primary: {
      main: '#1a599f',
      light: '#1f69bb',
      dark: "#174f8d",
      contrastText: '#fff',
    },
    secondary: {
      main:  '#497c1c',
      light: '#d0dea7',
      dark: "#174f8d",
      contrastText: '#fff',
    },
    background: {
      default: '#cee4f1',
      paper: "#f4f4f4",
    },
    color1: {
      main:  '#559f13',
    },
    color2: {
      main:  '#ec5915',
    },
    color3: {
      main:  '#a148b8',
    },
    color4: {
      main:  '#9e9e9e',
    },
    color5: {
      main: '#3400ff',
    },
    color6: {
      main: '#9e9e00',
    },
  },
  shape: {
    borderRadius: '3px'
  },
});

 const themeDark = createMuiTheme({
  mixins: {
    toolbar: {
        minHeight: 48,
        "@media (min-width:0px) and (orientation: landscape)": {
            minHeight: 48
        },
        "@media (min-width:600px)": {
            minHeight: 48
        }
    }
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#1a599f',
      light: '#1f69bb',
      dark: "#174f8d",
      contrastText: '#fff',
    },    
    secondary: {
      main: '#dcdcaa',
      light: 'transparent'
    },
    /*background: {
      default: "#f4f5fd"
    },*/
    color1: {
      main: '#8bc34a',
    },
    color2: {
      main: '#ff9800',
    },
    color3: {
      main: '#c586c0',
    },
    color4: {
      main: '#9e9e9e',
    },
    color5: {
      main: '#2196f3',
    },
    color6: {
      main: '#dcdcaa',
    },
  },
  shape: {
    borderRadius: '3px'
  },
});

function App() {
  const [auth, setAuth] = useState(GetAuthModel());
  const [userSettings, setUserSettings] = useState({themeDark: false});
  const currentTheme = userSettings.themeDark ? themeDark : themeLight
  const [ready, setReady] = useState(false);
  useEffect(() => {
    const readUserSettings = () => {
      let apitheme = Cookies.getJSON("usersettings");
      if (apitheme !== undefined && apitheme !== userSettings.themeDark)
        setUserSettings({themeDark: apitheme}); 
    }

    const handleAuth = (res, refreshToken) => {
      if (res) {
        if (res.isAuthenticated) {
          setAuth(res);
          Cookies.set("authtoken", refreshToken, {expires: 50000});
        }
      }
      setReady(true);
    }
    
    const readUser = () => {
      const cookietoken = Cookies.getJSON("authtoken");
      if (cookietoken !== undefined) {
        GetUserByRefreshToken(cookietoken, handleAuth);
      } else {
        setReady(true);
      }
    }
    
    readUserSettings();
    readUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [])

  return (
      ready && 
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <AuthContext.Provider value={{ auth, setAuth }}>
          <BrowserRouter>
            <AppMenu getState={userSettings} setState={setUserSettings}></AppMenu>
          </BrowserRouter>
        </AuthContext.Provider>
      </ThemeProvider>
  );
}

export default App;
