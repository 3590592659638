import React, { useState } from 'react';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import Typography from '@material-ui/core/Typography';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import Controls from '../../../controls/Controls';
import { Reset } from './AccountService';
import PageLayout from '../Infrastructure/PageLayout';
import { FormErrorMessage, IconHeader } from '../Infrastructure/PageElements';
import PaperPad from '../Infrastructure/PaperPad';

export default function ResetPassword(props) {
    const [errMsg, setErrMsg] = useState('');
    const [updated, setUpdated] = useState(false);

    const initialValues = {
        password: '',
        confirmPassword: '',
        hash: props.match.params.id
    }

    const validationSchema = yup.object().shape({
        password: yup.string().required('Zadej nové heslo').min(4, 'Heslo musí mít minimálně 4 znaky'),
        confirmPassword: yup.string().oneOf([yup.ref('password'), ''], 'Hesla se neshodují')
    });

    const onSubmit = (values, onSubmitProps) => {
        const handleResetPassword = (res) => {
            if (res) {
                if (res.errorCode === undefined) {
                    if (res.ResultCode === 1)
                        setUpdated(true);
                    else setErrMsg(res.Description);
                } else props.history.push({ pathname: '/noaccess', state: res });
            } else props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
            onSubmitProps.setSubmitting(false);
        }
        Reset(values.password, values.confirmPassword, values.hash, handleResetPassword);
    }

    const goLogin = (e) => {
        props.history.push('/login');
    }

    return (
        <PageLayout width="sm">
            <PaperPad width='lg'>
                <div style={{ "display": updated ? "none" : "block" }}>
                    <IconHeader text="Nové heslo">
                        <LockOpenOutlinedIcon />
                    </IconHeader>
                    <Typography component="p" variant="body1">
                        Níže zadej a potvrď nové heslo pro zadanou emailovou adresou.
                    </Typography>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {formik => {
                            return (
                                <Form>
                                    <Controls.Input
                                        name="password"
                                        label="Nové heslo*"
                                        type="password"
                                    />
                                    <Controls.Input
                                        name="confirmPassword"
                                        label="Nové heslo znovu*"
                                        type="password"
                                    />
                                    <FormErrorMessage message={errMsg} />
                                    <Controls.Button 
                                        fullWidth type="submit" 
                                        text="Odeslat"
                                        disabled={formik.isSubmitting || Boolean(errMsg)} 
                                    />
                                </Form>
                            )
                        }
                        }
                    </Formik>
                </div>
                <div style={{ "display": updated ? "block" : "none" }}>
                    <Typography color="secondary" component="p" variant="body1" align="center">
                        Bylo nastaveno nové heslo, přihlašovací jméno máš v emailu.
                </Typography>
                    <Controls.Button fullWidth type="button" text="Přihlásit" onClick={goLogin} />
                </div>
            </PaperPad>
        </PageLayout>
    );
}
