import React, { useContext, useEffect, useState } from 'react';
import PageLayout from '../Infrastructure/PageLayout';
import { Button, Grid, makeStyles, Paper } from '@material-ui/core';
import ProgressPage from '../Infrastructure/ProgressPage';
import { GetScheduleData } from './DashboardService';
//import { SaveScheduleData } from '../Admin/AdminService';
import AuthContext from '../../App/AuthContext';
import { Pagination } from '@material-ui/lab';
import { FormHeader } from '../Infrastructure/PageElements';
//import Event from '../Partials/Event';
import Notification from '../../App/Notification';
import EventCard from '../Partials/EventCard';
import ScheduleDlg from '../Admin/ScheduleDlg';
import AddIcon from '@material-ui/icons/Add';
import { isInRole } from '../../../services/AuthApi';

const useStyles = makeStyles((theme) => ({
    header: {
        paddingBottom: theme.spacing(1),
    },
    list: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    pager: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    root: {
        width: '100%',
    },
    summary: {
        "& .MuiGrid-item p": {
            //fontSize: theme.typography.pxToRem(14),
        }
    },
    detail: {
        paddingTop: "0",
        paddingBottom: theme.spacing(1.5),
        "& .MuiGrid-item p": {
            //fontSize: theme.typography.pxToRem(14),
        }
    },
    /*amount: {
        textAlign: 'right',
        paddingRight: theme.spacing(1),
    },
    due: {
        color: theme.palette.warning.main
    },
    unpaid: {
        color: theme.palette.info.main
    },
    alignleft: {
        textAlign: 'left',
    },*/
    description: {
        fontStyle: 'italic',
        color: theme.palette.color4.main,
    },
    alignright: {
        textAlign: 'right',
    },
    event1: {
        color: theme.palette.color2.main,
    },
    event2: {
        color: theme.palette.color1.main,
    },
    event3: {
        color: theme.palette.color3.main,
    },
    event4: {
        color: theme.palette.color4.main,
    }
    }));


function Schedule(props) {
    const classes = useStyles();
    const defaultEvent = { id: -1, eventType: '2', eventDate: null, address: 'Nad Zlíchovem 255', city: 'Praha 5', description: '', team: '' };
    const authCtx = useContext(AuthContext);
    const [eventList, setEventList] = useState({ events: null, countPerPage: 5, currentPage: 1 });
    const [onRefreshChange, setOnRefreshChange] = useState(false);
    const [expanded, setExpanded] = useState(null);
    const closedNotify = { isOpen: false, message: '', type: '', title: '' };
    const [notify, setNotify] = useState(closedNotify);
    const [eventDlg, setEventDlg] = useState(null);

    useEffect(() => {
        const handle = (res) => {
            if (res) {
                if (res.errorCode === undefined)
                    setEventList(res);
                else props.history.push({ pathname: '/noaccess', state: res });
            } else {
                props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
            }
        }
        GetScheduleData(authCtx.auth.token, handle, eventList.countPerPage, eventList.currentPage)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx.auth.token, props.history, onRefreshChange])

    const refreshHandler = () => {
        setOnRefreshChange(!onRefreshChange);
    }
    const handlePanelChange = (panel) => {
        setExpanded(panel);
    };
    const changeHandler = (res) => {
        if (res && res.errorCode !== -1000) {
            if (res.errorCode === undefined) {
                if (res.ResultCode === 1) {
                    refreshHandler();
                } else setNotify({ isOpen: true, message: res.Description, type: 'error' });
            } else
                setNotify({ isOpen: true, message: 'Nepodařilo se provést požadovanou operaci ', type: 'error' });
        } else {
            setNotify({ isOpen: true, message: 'Služba není momentálně přístupná', type: 'error' });
        }
    }
    const newEvent = () => {
        setEventDlg(defaultEvent);
    }
    const pageChanged = (event, value) => {
        setExpanded(null);
        setEventList({ ...eventList, currentPage: value });
        refreshHandler();
    }

    return (
        eventList.events !== null ?
            (<PageLayout width="md">
                <Grid container className={classes.header}>
                    <Grid item xs={3}>
                        {(isInRole(authCtx.auth, 'Admin') || isInRole(authCtx.auth, 'Stats')) &&
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={newEvent}
                        >
                            Přidat akci
                        </Button>}
                    </Grid>
                    <Grid item xs={6}><FormHeader text="Kalendář akcí" /></Grid>
                    <Grid item xs={3}></Grid>
                </Grid>
                <div className={classes.root}>
                    {
                        eventList.events.map(e => {
                            return (
                                <EventCard key={e.id}
                                    event={e}
                                    expanded={expanded}
                                    setExpanded={handlePanelChange}
                                    refreshHandler={changeHandler}
                                />
                            )
                        })
                    }
                </div>
                <Paper>
                    <Pagination className={classes.pager}
                        count={Math.ceil(eventList.totalEvents / eventList.countPerPage)}
                        page={eventList.currentPage}
                        onChange={pageChanged}
                    />
                </Paper>
                <ScheduleDlg
                    name="eventdlg"
                    event={eventDlg}
                    setEvent={setEventDlg}
                    closeBtn={true}
                    refreshHandler={refreshHandler}
                />
                <Notification notify={notify} setNotify={setNotify} enableClickAway={true} />
            </PageLayout>) : (<ProgressPage />)
    );
}

export default Schedule;