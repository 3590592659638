import React, { useContext, useState } from 'react';
import AuthContext from '../../App/AuthContext';
import { Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import Notification from '../../App/Notification';
import { Form, Formik } from 'formik';
import Controls from '../../../controls/Controls';
import * as yup from 'yup';
import moment from "moment";
//import "moment/locale/cs";
import { SaveNews, DeleteNews } from './../Dashboard/DashboardService'
import { isInRole } from '../../../services/AuthApi';

function NewsDlg(props) {
    let authCtx = useContext(AuthContext);
    const closedNotify = { isOpen: false, message: '', type: '', title: '' };
    const { name, message = null, setMessage, refreshHandler } = props;
    const [notify, setNotify] = useState(closedNotify);

    const handleSubmit = (values, onSubmitProps) => {
        const handle = (res) => {
            if (res && res.errorCode !== -1000) {
                if (res.errorCode === undefined) {
                    if (res.ResultCode === 1) {
                        refreshHandler();
                        setMessage(null);
                    } else setNotify({ isOpen: true, message: res.Description, type: 'error' });
                } else
                    setNotify({ isOpen: true, message: 'Nepodařilo se provést požadovanou operaci ', type: 'error' });
            } else {
                setNotify({ isOpen: true, message: 'Služba není momentálně přístupná', type: 'error' });
            }
            onSubmitProps.setSubmitting(false);
        }
        if (values.delete)
            DeleteNews(authCtx.auth.token, values.id, handle);
        else SaveNews(authCtx.auth.token, values, handle);
    };

    const handleClose = () => {
        setNotify(closedNotify);
        setMessage(null);
    };

    const initialValues = message && message.id > 0
        ? { id: message.id, validTo: message.validTo, title: message.title, text: message.text, survey: message.survey, mainPage: message.mainPage, delete: false }
        : { id: -1, validTo: moment().add(7, 'd'), title: '', text: '', survey: false, mainPage: false, delete: false };

    const validationSchema = yup.object().shape({
        text: yup.string().when('delete', { is: (val) => { return !val }, then: yup.string().required('Zadej text') }),
        title: yup.string().when('delete', { is: (val) => { return !val }, then: yup.string().required('Zadej nadpis') }),
        validTo: yup.date().typeError('').nullable()
            .when('delete', { is: (val) => { return !val }, 
                                then: yup.date().typeError('').nullable()
                                         .required('Zadej datum platnosti')
                                         .min(moment().startOf('day').add(1, 'd'), 'Zadej alespoň zítřejší datum') 
                            }),
        /* validTo: yup.date().typeError('').nullable()
            .when('delete', { is : (val) => { return !val }, then: yup.date().typeError('').nullable().required('Zadej datum platnosti') })
            .when('survey', { is: (val) => { return val }, then: yup.date().typeError('').nullable().min(moment().startOf('day').add(1, 'd'), 'Zadej pozdější datum') }).nullable(), */
    });
    
    return (
        message &&
        <><Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {
                formik => {
                    return (
                        <Dialog id={name}
                            open={message !== null}
                            onClose={handleClose}
                            disableBackdropClick={true}
                            fullWidth={true}
                            maxWidth='md'
                        >
                            <Form>
                                <DialogContent>
                                    <Grid container spacing={1}>
                                        {(message.survey && ((message.likesCount ?? 0) !== 0 || (message.notLikesCount ?? 0) !== 0)) &&
                                        <Grid item xs={12}>
                                            <Typography color='textSecondary' variant='subtitle2'>Hlasování probíhá, můžeš pouze změnit platnost.</Typography>
                                        </Grid>}
                                        <Grid item xs={12} sm={6}>
                                            <Controls.DatePicker
                                                name="validTo"
                                                label="Platí do"
                                                size='small'
                                                disabled={formik.values.delete}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controls.Checkbox
                                                name="survey"
                                                label="Hlasování"
                                                size='small'
                                                color='secondary'
                                                disabled={formik.values.delete || (message.survey && ((message.likesCount ?? 0) !== 0 || (message.notLikesCount ?? 0) !== 0))}
                                                //disabled={formik.values.delete}
                                            />
                                        </Grid>
                                        {(isInRole(authCtx.auth, 'Admin') || isInRole(authCtx.auth, 'Leader')) &&
                                          !formik.values.survey &&
                                        <Grid item xs={12} sm={6}>
                                            <Controls.Checkbox
                                                name="mainPage"
                                                label="Na hlavní stránku"
                                                size='small'
                                                color='secondary'
                                                disabled={formik.values.delete}
                                            />
                                        </Grid>}
                                        <Grid item xs={12}>
                                            <Controls.Input
                                                name="title"
                                                label={formik.values.survey ? "Otázka*" : "Nadpis*"}
                                                size='small'
                                                disabled={formik.values.delete || (message.survey && ((message.likesCount ?? 0) !== 0 || (message.notLikesCount ?? 0) !== 0))}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controls.Input
                                                name="text"
                                                label={formik.values.survey ? "Popis*" : "Zpráva*"}
                                                multiline
                                                size='small'
                                                disabled={formik.values.delete || (message.survey && ((message.likesCount ?? 0) !== 0 || (message.notLikesCount ?? 0) !== 0))}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    {formik.values.id !== -1 && (!message.survey || ((message.likesCount ?? 0) === 0 && (message.notLikesCount ?? 0) === 0)) &&
                                        <Controls.Checkbox name="delete" label={formik.values.survey ? "Smazat hlasování" : "Smazat zprávu"} size='small' />
                                    }
                                    <Controls.Button onClick={handleClose} color="secondary" variant="outlined" size="small"
                                        text="Zpět"
                                        disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                    />
                                    <Controls.Button
                                        color="primary" variant="contained" size="small"
                                        text={formik.values.delete ? "Smazat" : "Uložit"}
                                        type="submit"
                                        disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                    />
                                </DialogActions>
                            </Form>
                        </Dialog>
                    )
                }
            }
        </Formik>
            <Notification notify={notify} setNotify={setNotify} enableClickAway={true} />
        </>
    )
}

export default NewsDlg;