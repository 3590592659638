import { Dialog, DialogContent, DialogActions, Grid, Typography, DialogTitle, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import React, { useContext, useState } from 'react';
import Controls from '../../../controls/Controls';
import AuthContext from '../../App/AuthContext';
import Notification from '../../App/Notification';
import { SaveStatsData } from './AdminService';

const useStyles = makeStyles(theme => ({
    hide: {
        display: 'none',
    },
}));

function StatsDlg(props) {
    const { name, stats = null, setStats, eventId=null, refreshHandler } = props;
    const classes = useStyles();
    const authCtx = useContext(AuthContext);
    const closedNotify = { isOpen: false, message: '', type: '', title: '' };

    const [notify, setNotify] = useState(closedNotify);

    const initialValues = stats ? {
        id: stats.id, participation: (stats.participationCount ?? false) === 1, points: stats.points,
        freeThrows: stats.freeThrows, freeThrowsMade: stats.freeThrowsMade, threePointsMade: stats.threePointsMade,
        fouls: stats.fouls
        }
        : { id: null};
    
    const minNumMsg = 'Minimálně 0';
    const maxNumMsg = 'Maximálně 200';
    const validationSchema = yup.object().shape({
        points: yup.number().nullable().min(0, minNumMsg).max(200, maxNumMsg),
        freeThrows: yup.number().nullable().min(0, minNumMsg).max(200, maxNumMsg),
        freeThrowsMade: yup.number().nullable().min(0, minNumMsg).max(200, maxNumMsg),
        threePointsMade: yup.number().nullable().min(0, minNumMsg).max(200, maxNumMsg),
        fouls: yup.number().nullable().min(0, minNumMsg).max(200, maxNumMsg),
    });

    const handleClose = () => {
        setNotify(closedNotify);
        setStats(null);
    };

    const changeHandler = (res) => {
        if (res && res.errorCode !== -1000) {
            if (res.errorCode === undefined) {
                if (res.ResultCode === 1) {
                    refreshHandler(res);
                    handleClose();
                } else setNotify({ isOpen: true, message: res.Description, type: 'error' });
            } else
                setNotify({ isOpen: true, message: 'Nepodařilo se provést požadovanou operaci ', type: 'error' });
        } else {
            setNotify({ isOpen: true, message: 'Služba není momentálně přístupná', type: 'error' });
        }
    }

    const handleSubmit = (values, onSubmitProps) => {
        const handle = (res) => {
            changeHandler(res);
            onSubmitProps.setSubmitting(false);
        }
        SaveStatsData(authCtx.auth.token, {...values, eventId: eventId} , handle)
    }

    return (
        stats && <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >{formik => {
                const participate = formik.values.participation;
                return (
                    <Dialog
                        id={name}
                        open={stats !== null}
                        onClose={handleClose}
                        disableBackdropClick={true}
                        fullWidth={true}
                    >
                        <DialogTitle disableTypography={true}><Typography color='secondary'>{stats.jerseyNumber} - {stats.playerName}</Typography></DialogTitle>
                        <Form style={{ width: '100%' }}>
                            <DialogContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={6} sm={4}>
                                        <Controls.Checkbox
                                            name="participation"
                                            label="Účast"
                                            size="small"
                                            margin="dense"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4} className={participate ? null : classes.hide}>
                                        <Controls.NumberInput
                                            name="points"
                                            label="Body"
                                            size="small"
                                            margin="dense"
                                            format="###"
                                            disabled ={!participate}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4} className={participate ? null : classes.hide}>
                                        <Controls.NumberInput
                                            name="threePointsMade"
                                            label="Trojky"
                                            size="small"
                                            margin="dense"
                                            format="###"
                                            disabled ={!participate}
                                        /> 
                                    </Grid>
                                    <Grid item xs={6} sm={4} className={participate ? null : classes.hide}>
                                        <Controls.NumberInput
                                            name="freeThrowsMade"
                                            label="Šestky"
                                            size="small"
                                            margin="dense"
                                            format="###"
                                            disabled ={!participate}
                                        /> 
                                    </Grid>
                                    <Grid item xs={6} sm={4} className={participate ? null : classes.hide}>
                                        <Controls.NumberInput
                                            name="freeThrows"
                                            label="Šestky celkem"
                                            size="small"
                                            margin="dense"
                                            format="###"
                                            disabled ={!participate}
                                        /> 
                                    </Grid>
                                    <Grid item xs={6} sm={4} className={participate ? null : classes.hide}>
                                        <Controls.NumberInput
                                            name="fouls"
                                            label="Fauly"
                                            size="small"
                                            margin="dense"
                                            format="###"
                                            disabled ={!participate}
                                        /> 
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Controls.Button
                                    size="small"
                                    color="secondary"
                                    variant="outlined"
                                    type="button"
                                    onClick={() => { handleClose(); }}
                                    text="Zpět"
                                    disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                />
                                <Controls.Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    text="Uložit"
                                    disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                    />
                            </DialogActions>
                        </Form>
                    </Dialog>
                )
            }}
            </Formik>
            <Notification notify={notify} setNotify={setNotify} enableClickAway={true} />
        </>
    );
}

export default StatsDlg;