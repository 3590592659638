import React from 'react'
import { Field } from 'formik'
import { FormControl, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core'
import OutlinedBox from './OutlinedBox'
//import TextError from './TextError'

function CheckboxGroup (props) {
  const { label, name, color="primary", options, ...rest } = props
  
  const convertToDefEventPara = (name, value) => {
    return {target: { name, value }}
  }
  
  return (
      <Field name={name}>
         {({ form, field }) => {
           const error = form.errors[field.name];
           const touched = form.touched[field.name];
           return <>
           <OutlinedBox label={label} color={color}>
           {options.map((option) => {
            return (
               <FormControl key={option.key}>
                 <FormControlLabel
                   control={<Checkbox 
                            name={`chkb${option.key}`} 
                            color = {color} 
                            value = {option.key}
                            checked={field.value.includes(option.key)}
                            //onBlur={(e) => {field.touched(e)}}
                            onChange = {(e) => {
                              var targetVal = field.value.filter( x => (x !== e.target.value));
                              if (e.target.checked) targetVal.push(e.target.value);
                              form.touched[field.name] = true;
                              field.onChange(convertToDefEventPara(name, targetVal));
                             }}
                            //indeterminate = {false}
                            //indeterminateIcon
                            //required=true
                        />}
                    label={option.label}
                    {...rest}
                 />
                </FormControl>
               )
          })}
          </OutlinedBox>
          <FormHelperText error={touched && !!error}>{touched && error}</FormHelperText>
          </>
         }}
      </Field>      
  )
}

export default CheckboxGroup 