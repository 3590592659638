import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import AuthContext from '../../App/AuthContext';
import { GetDashboardData } from './DashboardService';
import PageLayout from '../Infrastructure/PageLayout';
import NewsCard from '../Partials/NewsCard';
import MyDebtCard from '../Partials/MyDebtCard';
import AddIcon from '@material-ui/icons/Add';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import NewsDlg from '../Partials/NewsDlg';
import LastGames from '../Partials/LastGames';
import EventCard from '../Partials/EventCard';
import { withRouter } from 'react-router-dom';
import { FormHeader } from '../Infrastructure/PageElements';
import ProgressPage from '../Infrastructure/ProgressPage';
import { isInRole } from '../../../services/AuthApi';
import GameStats from '../Partials/GameStats';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
    alignright: {
        textAlign: 'right',
    },
}));

function DashBoardAuth(props) {
    const authCtx = useContext(AuthContext);
    const [apiDashboard, setApiDashboard] = useState(null);
    const [message, setMessage] = useState(null);
    const [onRefreshChange, setOnRefreshChange] = useState(false);
    const classes = useStyles();
    const [nextExpanded, setNextExpanded] = useState(null);
    const [statsExpanded, setStatsExpanded] = useState(-1);

    const refreshHandler = () => {
        setOnRefreshChange(!onRefreshChange);
    }

    const handleNew = () => {
        setMessage({ id: null, title: '', text: '', validTo: null, targets: [] });
    }

    const handleRedirect = () => {
        props.history.push('/schedule');
    }

    const handleNextChange = (panel) => {
        setNextExpanded(panel);
    };

    useEffect(() => {
        const getData = () => {
            const handler = (res) => {
                setApiDashboard(res);
            }
            GetDashboardData(authCtx.auth.token, handler);
        }
        getData();
    }, [authCtx.auth.token, onRefreshChange]);

    return (
        apiDashboard ? (
        <PageLayout width="md">
            <Grid container>
                {apiDashboard.debt && apiDashboard.debt.length > 0 &&
                    <Grid item xs={12}>
                        <MyDebtCard debts={apiDashboard.debt} email={apiDashboard.myEmail} />
                    </Grid>
                }
                {
                 apiDashboard.nextEvent && <FormHeader text="Nejbližší akce"/>   
                }
                {apiDashboard.nextEvent &&
                    <Grid item xs={12}>
                        <EventCard
                            event={apiDashboard.nextEvent}
                            expanded={nextExpanded}
                            setExpanded={handleNextChange}
                            refreshHandler={refreshHandler}
                        />
                    </Grid>
                }
                {apiDashboard.lastResults &&
                    <Grid item xs={12}>
                        <LastGames results={apiDashboard.lastResults} />
                    </Grid>
                }
                {(isInRole(authCtx.auth, 'Player') || isInRole(authCtx.auth, 'Fan')) && 
                <Grid container className={classes.root}>
                    <Grid item xs={6}>
                        <Button
                            size="small" color="primary" variant="contained" type="button" startIcon={<AddIcon />}
                            onClick={handleNew}
                        >
                            Nová zpráva
                    </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.alignright}>
                        <Button
                            size="small" color="secondary" variant="text" type="button" endIcon={<ChevronRightIcon />}
                            onClick={handleRedirect}
                        >
                            Kalendář
                    </Button>
                    </Grid>
                </Grid>}
                {apiDashboard.news && apiDashboard.news.length > 0 && apiDashboard.news.map(m => {
                    return (
                        <Grid item xs={12} key={m.id}>
                            <NewsCard news={m} refreshHandler={refreshHandler} />
                        </Grid>)
                })
                }
                {apiDashboard.stats && apiDashboard.stats.length > 0 && 
                <Grid item xs={12}>
                    <FormHeader text="Statistiky" align="left"/>
                    {apiDashboard.stats.map((s, index) => {
                        return (
                            <GameStats key={index}
                                data={s}
                                expanded={statsExpanded}
                                setExpanded={setStatsExpanded}
                            />
                        )
                    })}
                </Grid>
                }
            </Grid>
            <NewsDlg
                message={message}
                setMessage={setMessage}
                refreshHandler={refreshHandler}
            />
        </PageLayout>)
        :  (<ProgressPage />)
    );
}
export default withRouter(DashBoardAuth);