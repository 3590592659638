import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PageLayout from '../Infrastructure/PageLayout';
import ProgressPage from '../Infrastructure/ProgressPage';
import DisplayValue, { DisplayName } from '../Partials/DisplayValue';
import { GetRecruitmentData } from './HomeService';
import Image from 'material-ui-image'
import sokol from '../../../static/map_4.jpg'

const useStyles = makeStyles(theme => ({
    row: {
      padding: theme.spacing(1),
    },
    text: {
        textIndent: theme.spacing(6),
        margin: theme.spacing(1),
    },
    padding: {
        padding: theme.spacing(1),
    },
    color: {
        color: theme.palette.text.secondary,
    },
    address: {
        display: 'flex',
        flexDirection: 'column',
        //paddingTop: 'calc(10%)',
        //paddingBottom: 'calc(10%)',
        alignItems: 'center',
    },
  }));

function Recruitment(props) {
    const classes = useStyles();
    const [apiData, setApiData] = useState(null);

    useEffect(() => {
        const handler = (res) => {
            setApiData(res);
        }
        GetRecruitmentData(handler);
    }, []);

    return (
        apiData ? (
        <PageLayout width="lg">
            <Typography className={classes.text} color="secondary" variant="body2">
                Vítejte na stránkách pražského amatérského basketbalového klubu Sokol Zlíchov.
            </Typography>
            <Paper className={classes.padding}>
                <Grid spacing={1} container>
                    <Grid item xs={12} sm={8}>
                       <div className={classes.address}>
                       <Typography color='secondary'>Adresa</Typography>
                        <Typography>Nad Zlíchovem 255</Typography>
                        <Typography>150 00 Praha 5</Typography>
                        <Typography color='secondary'>GPS</Typography>
                        <Typography>50.045641804645484</Typography>
                        <Typography>14.405751283228058</Typography>
                       </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {/* <div className={classes.image}> */}
                            <Image disableTransition={true} color='transparent' /* animationDuration={10} */ aspectRatio={(4/3)} src={sokol}/>
                        {/* </div> */}
                    </Grid>
                </Grid>
            </Paper>
            
            <Typography className={classes.text} color="secondary" variant="body2">
                Naše týmy hrají soutěže Pražského basketbalového svazu.
            </Typography>
            {apiData.teams && apiData.teams.length > 0 &&
                <Paper className={classes.padding}>
                {apiData.teams.map( r => (
                    <Grid spacing={1} className={classes.row} container key={r.id}>
                        <Grid item xs={12} sm={6}>{r.name}</Grid>
                        <Grid className={classes.color} item xs={12} sm={6}>{r.league}</Grid>
                    </Grid>            
                ))
                }
                </Paper>
            }
            <Typography className={classes.text} color="secondary" variant="body2">
                Máte-li zájem hrát v našem klubu, kontaktujte některého z funkcionářů:
            </Typography>
            <Paper className={classes.padding}>
            {apiData.management && apiData.management.map( r => (
                <Grid spacing={1} className={classes.row} container key={r.id}>
                    <Grid item xs={12} sm={6} md={3} className={classes.color}>{r.description}</Grid>
                    <Grid item xs={12} sm={6} md={3}><DisplayName firstName={r.firstName} lastName={r.lastName} title={r.title}/></Grid>
                    <Grid item xs={12} sm={6} md={3}>{r.email}</Grid>
                    <Grid item xs={12} sm={6} md={3}><DisplayValue type='phone' value={r.phone}/></Grid>
                </Grid>
            ))
            }
            </Paper>
        </PageLayout>)
        : (<ProgressPage />)
    );
}

export default Recruitment;