import { Card, CardContent, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import DisplayValue from './DisplayValue';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        '& .MuiCardContent-root': {
            padding: theme.spacing(0),
        }
    },
    header: {
        paddingTop: theme.spacing(1),
        /* fontSize: '1.1em', */
        paddingBottom: theme.spacing(0.5),
    },
    item: {
        textAlign: 'center',
        marginLeft: 'auto',
        /* color: theme.palette.color5.main, */
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5)
    },
    value: {
        /* fontSize: '1.2em', */
        color: theme.palette.secondary.main,
        /* textAlign: 'center',
        width: '100%', */
    },
    small: {
        paddingTop: theme.spacing(1),
        fontSize: "0.85em",
        fontStyle: "italic",
        color: theme.palette.text.secondary,
    },
    photo: {
        paddingRight: theme.spacing(3)
    },
    image: {
        height: 'auto',
        width: '45px',
        verticalAlign: 'middle'
    },
}));

function MyPerformance(props) {
    const { data, ...other } = props;
    const classes = useStyles();
    //const players = require.context('../../../../public/Players', true);
    //const photo = players(`./${data.peopleId}.jpg`);
    return (
        <Card {...other} variant="outlined" className={classes.root}>
            <div>
            <CardContent>
                    <Grid container spacing={1}>
                        {/* <Grid item className={classes.photo} xs={12}>
                            <img className={classes.image} src={photo.default} alt='' />
                        </Grid> */}                        
                        <Grid item xs={12} sm={12} md={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={7}>
                                    Odehraných zápasů
                                </Grid>
                                <Grid item xs={5}>
                                    <DisplayValue className={classes.value} value={data.sumGames} type={"integer"} />
                                </Grid>
                                <Grid item xs={7}>
                                    Nastřílených bodů
                                </Grid>
                                <Grid item xs={5}>
                                    <DisplayValue className={classes.value} value={data.sumPoints} type={"integer"} />
                                </Grid>
                                <Grid item xs={7}>
                                    Celkem trojek
                                </Grid>
                                <Grid item xs={5}>
                                    <DisplayValue className={classes.value} value={data.sumThreePoints} type={"integer"} />
                                </Grid>
                                <Grid item xs={7}>
                                    Procento šestek
                                </Grid>
                                <Grid item xs={5}>
                                    <DisplayValue className={classes.value} value={data.procFreeThrows} type={"decimal"} decimals={2} />
                                </Grid>
                                <Grid item xs={7}>
                                    Průměr faulů
                                </Grid>
                                <Grid item xs={5}>
                                    <DisplayValue className={classes.value} value={data.avgFouls} type={"decimal"} decimals={2} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={7}>
                                    Bodů v zápase
                                </Grid>
                                <Grid item xs={5}>
                                    <DisplayValue className={classes.value} value={data.maxGamePoints.value} type={"integer"} />
                                </Grid>
                                <Grid item xs={12}>
                                    <DisplayValue className={classes.small} value={data.maxGamePoints.date} type="date"/> <span className={classes.small}>{data.maxGamePoints.description}</span>
                                </Grid>
                                <Grid item xs={7}>
                                    Šestek v zápase
                                </Grid>
                                <Grid item xs={5}>
                                    <DisplayValue className={classes.value} value={data.maxGameFreeThrows.value} type={"integer"} />
                                </Grid>
                                <Grid item xs={12}>
                                    <DisplayValue className={classes.small} value={data.maxGameFreeThrows.date} type="date"/> <span className={classes.small}>{data.maxGameFreeThrows.description}</span>
                                </Grid>
                                <Grid item xs={7}>
                                    Trojek v zápase
                                </Grid>
                                <Grid item xs={5}>
                                    <DisplayValue className={classes.value} value={data.maxGameThreePoints.value} type={"integer"} />
                                </Grid>
                                <Grid item xs={12}>
                                    <DisplayValue className={classes.small} value={data.maxGameThreePoints.date} type="date"/> <span className={classes.small}>{data.maxGameThreePoints.description}</span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </div>
        </Card>
    );
}

export default MyPerformance;