import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, CircularProgress, FormControl, Grid, InputLabel, makeStyles, MenuItem, Paper, Select } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../App/AuthContext';
import { FormHeader } from '../Infrastructure/PageElements';
import PageLayout from '../Infrastructure/PageLayout';
import ProgressPage from '../Infrastructure/ProgressPage';
import GameStats from '../Partials/GameStats';
import LeagueTable from '../Partials/LeagueTable';
import PersonalStats from '../Partials/PersonalStat';
import { GetArchiveFilter } from './DashboardService';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },
    backdrop:{
        zIndex: 2000,
    },
}));

function Archive(props) {
    const classes = useStyles();
    const authCtx = useContext(AuthContext);
    const [apiFilter, setApiFilter] = useState(null);
    const [onRefreshChange, setOnRefreshChange] = useState(false);
    const [expanded, setExpanded] = useState(-1);
    const [waiting, setWaiting] = useState(false);

    const refreshHandler = () => {
        setOnRefreshChange(!onRefreshChange);
    }

    const handlePanelChange = (panel) => {
        setExpanded(panel);
    };

    const onPanelChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const onTeamChange = (e) => {
        var f = apiFilter.filter;
        f.teamId = e.target.value;
        f.leagueId = null;
        setExpanded(-1);
        setApiFilter({ ...apiFilter, filter: f });
        refreshHandler();
    }
    const onLeagueChange = (e) => {
        var f = apiFilter.filter;
        f.leagueId = e.target.value;
        setExpanded(-1);
        setApiFilter({ ...apiFilter, filter: f });
        refreshHandler();
    }

    const goTopPerform = () => {
        props.history.push('/archive/top');
    }

    useEffect(() => {
        const handler = (res) => {
            if (res && res.errorCode === undefined) {
                setApiFilter(res);
                setWaiting(false);
            }
            else props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
        }
        setWaiting(true);
        GetArchiveFilter(authCtx.auth.token, apiFilter ? apiFilter.filter : null, handler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx.auth.token, onRefreshChange]);

    return (
        apiFilter ? (<>
            <Backdrop open={waiting} className={classes.backdrop}>
               <CircularProgress color='secondary'/>
            </Backdrop>        
            <PageLayout width="md">
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Button
                            size="small"
                            color="secondary"
                            variant="text"
                            margin="none"
                            startIcon={<ChevronLeftIcon />}
                            onClick={goTopPerform}
                        >
                            TOP 10
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <FormHeader text="Archiv" />
                    </Grid>
                </Grid>
                <Paper className={classes.root}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <InputLabel color='secondary' shrink>Tým</InputLabel>
                            <FormControl variant='outlined' margin='none' size='small' fullWidth>
                                <Select
                                    name="teamId"
                                    value={apiFilter.filter.teamId}
                                    onChange={onTeamChange}
                                    color='secondary'
                                >
                                    {apiFilter.teamLOV.map(item => (
                                        <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel color='secondary' shrink>Soutěž</InputLabel>
                            <FormControl variant='outlined' margin='none' size='small' fullWidth>
                                <Select
                                    name="leagueId"
                                    value={apiFilter.filter.leagueId ?? ''}
                                    onChange={onLeagueChange}
                                    color='secondary'
                                >
                                    {apiFilter.leagueLOV.map(item => (
                                        <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
                <Accordion key={'X1'} expanded={'X1' === expanded} onChange={onPanelChange('X1')} >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} /* id={event.id} */>
                        Tabulka
                    </AccordionSummary>
                    <AccordionDetails>
                        <LeagueTable data={apiFilter.stats.table} margin={false} />
                    </AccordionDetails>
                </Accordion>
                {apiFilter.stats.stats && apiFilter.stats.stats.length > 0 && 
                <Accordion key={'X2'} expanded={'X2' === expanded} onChange={onPanelChange('X2')} >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} /* id={event.id} */>
                    <Grid container>
                        <Grid item xs={12}>
                            Osobní statistiky
                        </Grid>
                    </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <PersonalStats data={apiFilter.stats.stats} margin={false} participation={true} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>}
                {apiFilter.stats.gameStats.map(g => (
                    <GameStats key={g.game.id}
                        data={g}
                        expanded={expanded}
                        setExpanded={handlePanelChange}
                    />
                ))
                }
            </PageLayout>
            </>) : (<ProgressPage />)
    );
}

export default Archive;