import React from 'react';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        padding: '0'
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    content: {
        flexGrow: 1,
        width: '100%'
    },
    contentCenter: {
        flexGrow: 1,
    },

}));

function PageLayout(props) {
    const classes = useStyles();
    const  { width, center=false} = props;
    return (
        <Container className={classes.root} component="main" maxWidth={width ?? 'lg' }>
            <div className={classes.paper}>
                <div className={center ? classes.contentCenter : classes.content}>
                    { props.children }
                </div>
            </div>
        </Container>
    );
}

export default PageLayout;