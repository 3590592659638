import React from 'react';
import { fmtNumber, fmtDate, fmtDateTime, fmtPhone, fmtAddress, fmtDayOfWeek, fmtName, fmtRate, fmtPercentage } from '../Infrastructure/FormattingLib';

function DisplayValue(props) {
    const { value, type, className, decimals=0,  ...other } = props;
    let val = value;
    switch (type) {
        case "phone": val = fmtPhone(val); break;
        case "integer": val = fmtNumber(val, 0); break;
        case "currency":val = fmtNumber(val, decimals) + ' Kč'; break;
        case "decimal": val = fmtNumber(val, decimals); break;
        case "date": val = fmtDate(val); break;
        case "datetime": val = fmtDateTime(val); break;
        case "dayofweek": val = fmtDayOfWeek(val); break;
        default: break;
    }

    return (
        <span className={className} {...other}>{val}</span>);
}
export default DisplayValue;

export const DisplayAddress = (props) => {
    const { address=null, city=null, className, ...other } = props;
    return (
        <span className={className} {...other}>{fmtAddress(address, city)}</span>
    )
};

export const DisplayName = (props) => {
    const { firstName=null, lastName=null, title=null, className, ...other } = props;
    return (
        <span className={className} {...other}>{fmtName(firstName, lastName, title)}</span>
    )
};

export const DisplayRate = (props) => {
    const { value1=null, value2=null, decimals=1, className, ...other } = props;
    return (
        <span className={className} {...other}>{fmtNumber(fmtRate(value1, value2, decimals), decimals)}</span>
    )
};

export const DisplayPercentage = (props) => {
    const { value1=null, value2=null, decimals=1, className, ...other } = props;
    return (
        <span className={className} {...other}>{fmtNumber(fmtPercentage(value1, value2, decimals), decimals)}</span>
    )
};

export const DisplayList = (props) => {
    const {list=null, itemFn, separator=", ", className, ...other } = props;
    let result = "";
    list.map(l => {
        result = result + (result==="" ? "" : separator) + itemFn(l);
        return false;
    })
    return list ? <span className={className} {...other}>{result}</span> : null;
}