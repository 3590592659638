import { makeStyles, Paper, withWidth } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    paper: {
        /* padding: theme.spacing(1), */
        marginBottom: theme.spacing(1),
        width: '100%'
    },
    width100: {
        width: '100%'
    },
    root: {
        width: '100%',
        textAlign: 'left',
        border: '1px solid #808080',
        borderCollapse: 'collapse',
        "& td": {
            padding: theme.spacing(0.5),
            border: '1px solid #808080',
            textAlign: 'center',
            "&.left": {
                textAlign: 'left',
            }
        },
        "& tr": {
            "&.light": {
                color: theme.palette.text.secondary,
            },
            "&.header": {
                color: theme.palette.secondary.main,
                background: theme.palette.secondary.light,
            }

        },

    }
    ,
}));


function LeagueTable(props) {
    const classes = useStyles();
    const { data = null, margin=true } = props;
    const { width } = props;
    var reduce = width === 'xs' || width === 'sm';
    return (
        data &&
        <Paper className={margin ? classes.paper: classes.width100}>
            <table className={classes.root}>
                <thead>
                    <tr className='header'>
                        <td colSpan={reduce ? 4 : 9}>{data.league}</td>
                    </tr>
                    <tr className='header'>
                        <td>Pořadí</td>
                        <td className='left'>Družstvo</td>
                        {!reduce && <td>Z</td>}
                        {!reduce && <td>V</td>}
                        {!reduce && <td>P</td>}
                        {!reduce && <td>K</td>}
                        <td>Skóre</td>
                        {!reduce && <td>Rozdíl</td>}
                        <td>Body</td>
                    </tr>
                </thead>
                <tbody>
                    {data.table.map(t => (
                        <tr key={t.position}>
                            <td>{t.position}</td>
                            <td className='left'>{t.teamName}</td>
                            {!reduce && <td>{t.played}</td>}
                            {!reduce && <td>{t.won}</td>}
                            {!reduce && <td>{t.lost}</td>}
                            {!reduce && <td>{t.contumacy}</td>}
                            <td>{t.scorePlus} : {t.scoreMinus}</td>
                            {!reduce && <td>{t.scorePlus - t.scoreMinus}</td>}
                            <td>{t.points}</td>
                        </tr>
                    ))
                    }
                </tbody>
                {!reduce && <tfoot>
                    <tr className='light'>
                        <td colSpan={9}>Z - počet zápasů, V - vítězství, P - porážky, K - kontumace</td>
                    </tr>
                </tfoot>}
            </table>
        </Paper>
    );
}

export default withWidth()(LeagueTable);