import React, { useState, useContext } from 'react';
import AuthContext from '../../App/AuthContext';
import clsx from 'clsx';
import { fmtDate } from '../Infrastructure/FormattingLib';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { SwitchNewsLike } from './../Dashboard/DashboardService';

import { Badge, Card, CardActions, CardContent, CardHeader, Collapse, Fade, IconButton, makeStyles, Typography, withWidth } from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NewsDlg from './NewsDlg';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
        '& .MuiCardHeader-root': {
            padding: theme.spacing(0, 0, 0.5, 0),
            '& .MuiCardHeader-content': {
                '& .MuiCardHeader-title': {
                    fontSize: "1.1rem",
                },
                '& .MuiCardHeader-subheader': {
                    fontSize: "0.85rem",
                    fontStyle: 'italic',
                    color: theme.palette.color4.main
                },
            }
        },
        '& .MuiCardActions-root': {
            padding: `0 ${theme.spacing(1)}px`,
        },
        '& .MuiCardContent-root': {
            padding: theme.spacing(0.5),
            textIndent: theme.spacing(3),
        },
    },
    news: {
        '& .MuiCardHeader-content': {
            '& .MuiCardHeader-title': {
                color: theme.palette.secondary.main,
            },
        }
    },
    survey: {
        '& .MuiCardHeader-content': {
            '& .MuiCardHeader-title': {
                color: theme.palette.color2.main
            },
        }        
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    indent: {
        textIndent: theme.spacing(1),
    },
    badgeRoot: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

function NewsCard(props) {
    const authCtx = useContext(AuthContext);
    const classes = useStyles();
    const { news, refreshHandler=null } = props;
    const [message, setMessage] = useState(null);
    const [contentExpanded, setContentExpanded] = useState(news.isNew && !news.isMy);
    const { width } = props;
    
    const handleExpandClick = () => {
        setContentExpanded(!contentExpanded);
    };

    const handleEdit = () => {
        setMessage(news);
    }

    const handleLikeClick = (id, value) => {
        const handle = (res) => {
            if (res.ResultCode === 1) {
                refreshHandler();
            }
        }
        SwitchNewsLike(authCtx.auth.token, id, value, handle);
    }

    const reducedTextLen = () => {
        switch (width) {
            case "xs": return 30;
            case "sm": return 45;
            case "md": return 70;
            default: return 100;
        }
        
    }
    const getHdrClass = (survey) => {
        return survey ? classes.survey : classes.news;
    }

    return (<>
        <Card key={news.id} className={classes.root}>
            <CardHeader
                title= {`${news.survey ? 'Hlasování - ' + news.title : news.title}`} 
                subheader={refreshHandler ? `${fmtDate(news.entryDate)} ${news.userName} ${news.survey ? ", konec hlasování " + fmtDate(news.validTo) : ""}`: `${fmtDate(news.entryDate)}`}
                avatar={news.isNew && <NotificationsActiveIcon color='secondary' fontSize='small' />}
                className={`${getHdrClass(news.survey)}`}
                onClick={handleExpandClick}
            />
            <CardActions disableSpacing>
                {news.isMy &&
                    <IconButton size='small' onClick={handleEdit}>
                        <EditIcon color='secondary' />
                    </IconButton>
                }
                {refreshHandler &&
                <div className={classes.badgeRoot}>
                    <IconButton size='small' onClick={() => { handleLikeClick(news.id, true) }}>
                        <Badge badgeContent={news.likesCount} color='primary'
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <ThumbUpAltOutlinedIcon color={news.myLikeResult === null || !news.myLikeResult ? 'disabled' : 'secondary'} size='small' />
                    </Badge>
                    </IconButton>
                    <IconButton size='small' onClick={() => { handleLikeClick(news.id, false) }}>
                        <Badge badgeContent={news.notLikesCount} color='error'
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        >
                            <ThumbDownAltOutlinedIcon color={news.myLikeResult === null || news.myLikeResult ? 'disabled' : 'secondary'} size='small' />
                        </Badge>
                </IconButton>
                </div>
                }
                <Fade in={!contentExpanded}>
                    <Typography className={classes.indent} variant="body2">{news.text.substring(0, reducedTextLen()) + ' ...'}</Typography>
                </Fade>
                <IconButton
                    className={clsx(classes.expand, { [classes.expandOpen]: contentExpanded, })}
                    onClick={handleExpandClick}
                    size='small'
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={contentExpanded}>
                <CardContent>
                    <Typography variant='body2'>{news.text}</Typography>
                </CardContent>
            </Collapse>
        </Card>
        <NewsDlg
                message={message}
                setMessage={setMessage}
                refreshHandler={refreshHandler}
            >
        </NewsDlg>        
    </>
    );
}

export default withWidth()(NewsCard);