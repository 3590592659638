import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { Field } from 'formik';

function NumberFormatCustom(props) {
    const { inputRef, onChange, format=undefined, suffix=undefined, prefix=undefined, decimals=0, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator=" "
            allowNegative={false}
            isNumericString
            decimalScale={decimals}
            format={format}
            prefix={prefix}
            suffix={suffix}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function NumberInput(props) {
    const { name, label, variant = "outlined", align='right', ...other } = props;

    /* const [values, setValues] = useState({
        textmask: '(1  )    -    ',
        numberformat: '420',
    }); */

    /* const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    }; */

    return (
        <Field name={name}>
            {
                ({ form, field }) => {
                    const error = form.errors[field.name];
                    const touched = form.touched[field.name];
                    return (
                        <TextField
                            margin="normal"
                            fullWidth
                            label={label}
                            value={field.value}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            name={name}
                            id={name}
                            variant={variant}
                            color="secondary"
                            spellCheck="false"
                            autoComplete="off"
                            InputProps={{ inputComponent: NumberFormatCustom, inputProps: {...other, style: {textAlign: align}}}}
                            {...other}
                            {...(error && touched && {error: true, helperText: error})}
                        />
                    )
                }
            }
        </Field>
    );
}
