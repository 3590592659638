import { makeStyles, Paper, withWidth } from '@material-ui/core';
import React from 'react';
import { DisplayPercentage, DisplayRate } from './DisplayValue';

const useStyles = makeStyles(theme => ({
    paper: {
        /* padding: theme.spacing(1), */
        margin: theme.spacing(1),
        width: '100%',
    },
    width100: {
        width: '100%',
    },
    root: {
        width: '100%',
        textAlign: 'left',
        border: '1px solid #808080',
        /* background: '#eff0f0', */
        borderCollapse: 'collapse',
        "& td": {
            paddingLeft: theme.spacing(0.25),
            paddingRight: theme.spacing(0.25),
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            border: '1px solid #808080',
            textAlign: 'center',
            "&.left": {
                textAlign: 'left',
            }
        },
        "& tr": {
            "&.light": {
                color: theme.palette.text.secondary,
            },
            "&.header": {
                color: theme.palette.secondary.main,
                background: theme.palette.secondary.light,
            },
            "&.footer": {
                color: theme.palette.secondary.main,
                background: theme.palette.secondary.light,
            }

        },

    }
    ,
}));

function PersonalStats(props) {
    const classes = useStyles();
    const { data = null, summary=null, margin=true, header=false, participation=true } = props;
    const { width } = props;
    var reduce = width === 'xs' || width === 'sm';    
    return (
        data &&
        <Paper className={margin ? classes.paper: classes.width100}>
            <table className={classes.root}>
                <thead>
                    {header && 
                    <tr className='header'>
                        <td colSpan={participation ? 11 : 7}>{data.league}</td>
                    </tr>
                    }
                    <tr className='header'>
                        <td>Č</td>
                        <td className='left'>Jméno</td>
                        {participation && <td>Účast</td>}
                        <td colSpan={participation && !reduce ? 2 : 1}>{"Body" + (participation && !reduce ? "(/z)" : "")}</td>
                        <td colSpan={reduce ? 1 : 2}>{"Šestky" + (!reduce ? "(%)" : "")}</td>
                        <td colSpan={participation  && !reduce ? 2 : 1}>{"Trojky" + (participation && !reduce ? "(/z)" : "")}</td>
                        <td colSpan={participation && !reduce ? 2 : 1}>{"Fauly" + (participation && !reduce ? "(/z)" : "")}</td>
                    </tr>
                </thead>
                <tbody>
                    {data.map(t => (
                        <tr key={t.id}>
                            <td>{t.jerseyNumber}</td>
                            <td className='left'>{t.playerName}</td>
                            {participation && <td>{t.participationCount}/{t.gamesCount}</td>}
                            <td>{t.points}</td>
                            {participation && !reduce && <td><DisplayRate value1={t.points} value2={t.participationCount}/></td>}
                            <td>{t.freeThrowsMade}/{t.freeThrows}</td>
                            {!reduce && <td><DisplayPercentage value1={t.freeThrowsMade} value2={t.freeThrows} decimals={0}/></td>}
                            <td>{t.threePointsMade}</td>
                            {participation && !reduce && <td><DisplayRate value1={t.threePointsMade} value2={t.participationCount}/></td>}
                            <td>{t.fouls}</td>
                            {participation && !reduce && <td><DisplayRate value1={t.fouls} value2={t.participationCount}/></td>}
                        </tr>
                    ))
                    }
                </tbody>
                {summary && <tfoot>
                    <tr className='footer'>
                        <td className='left' colSpan={2}>Účast: {summary.participationCount}</td>
                        <td>{summary.points}</td>
                        <td>{summary.freeThrowsMade}/{summary.freeThrows}</td>
                        {!reduce && <td><DisplayPercentage value1={summary.freeThrowsMade} value2={summary.freeThrows} decimals={0}/></td>}
                        <td>{summary.threePointsMade}</td>
                        <td>{summary.fouls}</td>
                   </tr>
                </tfoot>}
            </table>
        </Paper>
    );
}

export default withWidth()(PersonalStats);