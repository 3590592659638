import React, { useContext, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, InputLabel, makeStyles, TextField, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import DisplayValue, { DisplayAddress } from './DisplayValue';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';
import { SaveParticipation, SaveReferee } from '../Dashboard/DashboardService';
import AuthContext from '../../App/AuthContext';
import ScheduleDlg from '../Admin/ScheduleDlg';
import { isInRole } from '../../../services/AuthApi';

const useStyles = makeStyles((theme) => ({
    header: {
        paddingBottom: theme.spacing(0.5),
    },
    list: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    pager: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    root: {
        width: '100%',
    },
    marginTop: {
        marginTop: '0.5rem',
    },
    indentLeft: {
        textIndent: '1.5rem',
    },
    summary: {
        "& .MuiGrid-item p": {
            //fontSize: theme.typography.pxToRem(14),
        }
    },
    detail: {
        paddingTop: "0",
        paddingBottom: theme.spacing(1.5),
        "& .MuiGrid-item p": {
            //fontSize: theme.typography.pxToRem(14),
        }
    },
    description: {
        fontStyle: 'italic',
        color: theme.palette.color4.main,
    },
    alignright: {
        textAlign: 'right',
    },
    event1: {
        color: theme.palette.color2.main,
    },
    event2: {
        color: theme.palette.color1.main,
    },
    event3: {
        color: theme.palette.color3.main,
    },
    event4: {
        color: theme.palette.color4.main,
    }
}));

function EventCard(props) {
    const classes = useStyles();
    const authCtx = useContext(AuthContext);
    const { event = null, expanded = null, setExpanded = null, refreshHandler = null } = props;
    const [eventDlg, setEventDlg] = useState(null);
    const [participDescr, setParticipDescr] = useState(null);
    const [particip, setParticip] = useState(null)

    const getParticipationIcon = (status) => {
        switch (status) {
            case 0: return <ClearIcon fontSize='small' color='error' />;
            case 1: return <CheckIcon fontSize='small' className={classes.event2} />;
            default: return <span />
        }
    }

    const getEventColor = (eventType, participationEnabled) => {
        if (participationEnabled || !setExpanded) {
            switch (eventType) {
                case 1: return classes.event1;
                case 2: return classes.event2;
                case 3: return classes.event3;
                case 4: return null;
                default: return classes.event4;
            }
        } else return classes.event4;
    }

    const handlePanelChange = (panel) => (event, isExpanded) => {
        if (setExpanded !== null) {
            setExpanded(isExpanded ? panel : false);
        }
    };
    const handleSwitchChange = (value) => {
        const handle = (res) => {
            refreshHandler(res);
        }
        if (value !== null) {
            SaveParticipation(authCtx.auth.token, event.id, value, participDescr, handle);
        }
    };

    const editEvent = (e) => {
        setEventDlg(e);
    }
    const onDescrChange = (e) => {
        setParticipDescr(e.target.value);
    }

    const onParticipDescrSave = () => {
        const handle = (res) => {
            refreshHandler(res);
        }
        SaveParticipation(authCtx.auth.token, event.id, particip, participDescr, handle);
    }

    const allowReferee = (referee, type) => {
        var r = referee ? referee.find(e => e.refereeType === type) : null;
        return !r || r.myUser;
    }

    const getReferee = (referee, type) => {
        var r = referee ? referee.find(e => e.refereeType === type) : null;
        return <span className={classes.description}>{r && r.userName}</span>
    }

    const handleSwitchReferee = (value) => {
        const handle = (res) => {
            refreshHandler(res);
        }
        if (value !== null) {
            SaveReferee(authCtx.auth.token, event.id, value, handle);
        }
    }

    useEffect(() => {
        setParticipDescr(event.myDescription);
        setParticip(event.myParticipation);
    }, [event/* .myDescription, event.myParticipation */]);

    return (event && <>
        <Accordion key={event.id} expanded={event.id === expanded} onChange={handlePanelChange(event.id)} >
            <AccordionSummary expandIcon={setExpanded && <ExpandMoreIcon />} id={event.id}>
                <Grid container className={classes.summary}>
                    <Grid item xs={10}>
                        <Typography>
                            <DisplayValue value={event.eventDescription} type='text' className={getEventColor(event.eventType, event.participationEnabled)} />{event.eventDescription && <span> - </span>}
                            <DisplayValue value={event.eventDate} type='datetime' /> <DisplayValue value={event.eventDate} type='dayofweek' /> {event.description ? <span> - </span> : null}
                            <DisplayValue value={event.description} type='text' className={classes.description} />
                        </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.alignright}>
                        {setExpanded && <Typography>{event.registered} / {event.canceled}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography><DisplayAddress address={event.address} city={event.city} /></Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.detail}>
                {setExpanded &&
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item xs={12}>
                                    {event.participationEnabled &&
                                        <TextField id={"descr" + event.id} color="secondary" fullWidth size="small" label="Poznámka (max 30 znaků)"
                                            onChange={onDescrChange} value={participDescr ? participDescr : ''}
                                            inputProps={{ maxLength: 30 }}
                                            InputProps={{
                                                endAdornment: <IconButton size="small" color="secondary" onClick={onParticipDescrSave} disabled={particip === -1}><SaveAltIcon /></IconButton>
                                            }}
                                            variant="outlined"
                                        />}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            {event.participationEnabled &&
                                <ToggleButtonGroup
                                    exclusive
                                    size="small" id={event.id} value={event.myParticipation}
                                    onChange={(e, value) => { handleSwitchChange(value); }}
                                >
                                    <ToggleButton value={1}>
                                        Přijdu
                                    </ToggleButton>
                                    <ToggleButton value={0}>
                                        Omluva
                                    </ToggleButton>
                                    <ToggleButton value={-1}>
                                        Nevím
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            }
                        </Grid>
                        {(isInRole(authCtx.auth, 'Admin') || isInRole(authCtx.auth, 'Stats') || isInRole(authCtx.auth, 'Leader')) &&
                            <Grid item xs={3} className={classes.alignright}>
                                <Button
                                    variant="outlined"
                                    color='secondary'
                                    component="span"
                                    size="small"
                                    startIcon={<EditIcon color='secondary' font='small' />}
                                    onClick={() => { editEvent(event) }}
                                />
                            </Grid>}
                        {(event.homeMatch) && <>
                            <Grid item xs={4}>
                                <IconButton disabled={!allowReferee(event.referee, 10)} size="small" color={'inherit'} onClick={() => { handleSwitchReferee(10) }}>
                                    <LibraryBooksIcon />
                                </IconButton>
                                {getReferee(event.referee, 10)}
                            </Grid>
                            <Grid item xs={4}>
                                <IconButton disabled={!allowReferee(event.referee, 11)} size="small" color={'inherit'} onClick={() => { handleSwitchReferee(11) }}>
                                    <AccessAlarmsIcon />
                                </IconButton>
                                {getReferee(event.referee, 11)}
                            </Grid>
                            <Grid item xs={4}></Grid>                            
                        </>
                        }
                        <Grid item xs={12}>
                            <Grid container>
                                {
                                    event.participations.map(p => {
                                        return (
                                            <Grid key={p.userId} item xs={6} md={4}>
                                                <Typography className={classes.marginTop}>{getParticipationIcon(p.status)} {p.userName}</Typography>
                                                <InputLabel shrink className={classes.indentLeft}>{p.notice}</InputLabel>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </AccordionDetails>
        </Accordion>
        <ScheduleDlg
            name="eventdlg"
            event={eventDlg}
            setEvent={setEventDlg}
            closeBtn={true}
            refreshHandler={refreshHandler}
        />
    </>
    );
}

export default EventCard;