import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import PageLayout from '../Infrastructure/PageLayout';
import { GetDashboardData } from './HomeService';
import LastGames from '../Partials/LastGames';
import NewsCard from '../Partials/NewsCard';
import EventCard from '../Partials/EventCard';
import { FormHeader } from '../Infrastructure/PageElements';
import ProgressPage from '../Infrastructure/ProgressPage';
import AuthContext from '../../App/AuthContext';

function DashBoardNoAuth(props) {
    const authCtx = useContext(AuthContext);
    const [apiDashboard, setApiDashboard] = useState(null);

    useEffect(() => {
        const handler = (res) => {
            setApiDashboard(res);
        }
        if (authCtx === null || !authCtx.auth.isAuthenticated) GetDashboardData(handler);
    }, [authCtx, authCtx.auth.isAuthenticated]);

    return (
        apiDashboard ? (
        <PageLayout width="md">
            {
                apiDashboard.lastResults && <FormHeader text="Výsledky"/>
            }
            {apiDashboard.lastResults &&
                <Grid item xs={12}>
                    <LastGames results={apiDashboard.lastResults} />
                </Grid>
            }
            {
                apiDashboard.nextEvent && <FormHeader text="Nejbližší akce"/>
            }
            {apiDashboard.nextEvent &&
                <Grid item xs={12}>
                    <EventCard
                        event={apiDashboard.nextEvent}
                    />
                </Grid>
            }
            {
                apiDashboard.news && apiDashboard.news.length > 0 && <FormHeader text="Aktuality"/>
            }            
            {apiDashboard.news && apiDashboard.news.length > 0 && apiDashboard.news.map(m => {
                return (
                    <Grid item xs={12} key={m.id}>
                        <NewsCard news={m} />
                    </Grid>)
            })
            }
        </PageLayout>)
        : <ProgressPage />
    );
}

export default DashBoardNoAuth;