import {callWebApiAuth, callHandleResult, JsonToFilter } from "../../../services/WebApi";

export const GetPayments = (token, handler, count=4, page=1, filter=null) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Treasury', 'GET', {count: count, page: page, filter: JsonToFilter(filter)}, token, handleGetData);
}

export const DeleteTransaction = (token, handler, id) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Treasury', 'DELETE', {id: id, all: true}, token, handleGetData);
}

export const DeletePayment = (token, handler, id) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Treasury', 'DELETE', {id: id, all: false}, token, handleGetData);
}

export const InsertPayment = (token, handler, id, amount, date) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Treasury', 'POST', {id: id, amount: amount, date: date, canceled: false}, token, handleGetData);
}

export const GetPeopleList = (token, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Payment', 'GET', null, token, handleGetData);
}

export const SavePayments = (token, data, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Payment', 'POST', data, token, handleGetData);
}

export const GetCashBalance = (token, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Balance', 'Get', null, token, handleGetData);
}

export const SaveScheduleData = (token, data, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Schedule', 'POST', data, token, handleGetData);
}

export const GetStatFilter = (token, teamId, gameId, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Stats', 'GET', {teamId: teamId, gameId: gameId}, token, handleGetData);
}

export const SaveStatsData = (token, data, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Stats', 'POST', data, token, handleGetData);
}

export const GetLeagueList = (token, leagueId, teamId, handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Maintenance', 'GET', { leagueId: leagueId, teamId: teamId }, token, handleGetData);
}

export const EndSeason = (token, leagueId, handler) => {
  const handleSetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Maintenance', 'PUT', { Id: leagueId }, token, handleSetData);
}

export const SaveSeason = (token, data, handler) => {
  const handleSetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Maintenance', 'POST', data, token, handleSetData);
}

export const GetAppInfo = (token, handler) => {
  const handleSetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Admin', 'GET', null, token, handleSetData);
}

export const GetAppLog = (token, handler, count=4, page=1, filter=null) => {
  const handleSetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Logging', 'GET', {count: count, page: page, filter: JsonToFilter(filter)}, token, handleSetData);
}

export const GetUserProfile = (token, id, handler) => {
  const handleSetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Register', 'GET', {id: id}, token, handleSetData);
}

export const SaveUserProfile = (token, data, handler) => {
  const handleSetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAuth('Register', 'POST', data, token, handleSetData);
}
