import React, { useContext, useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as yup from 'yup'
import Controls from '../../../controls/Controls';
import PageLayout from '../Infrastructure/PageLayout';
import { FormHeader } from '../Infrastructure/PageElements';
import PaperPad from '../Infrastructure/PaperPad';
import AuthContext from '../../App/AuthContext';
import Notification from '../../App/Notification';
import { GetReminder, SaveReminder } from './DashboardService';

const useStyles = makeStyles(theme => ({
    hide: {
        display: 'none',
    },
}));

function UserSettings(props) {
    let authCtx = useContext(AuthContext);
    const classes = useStyles();
    const [formValues, setFormValues] = useState(null);
    const closedNotify = { isOpen: false, message: '', type: '', title: '' };
    const [notify, setNotify] = useState(closedNotify);

    const validationSchema = yup.object({
        trainingVal: yup.number().nullable().when('training', { is: (val) => { return val }, then: yup.number().nullable().required('Zadej minuty').min(15, 'Minimálně 15 minut') }),
        gameVal: yup.number().nullable().when('game', { is: (val) => { return val }, then: yup.number().nullable().required('Zadej minuty').min(15, 'Minimálně 15 minut') }),
    })

    useEffect(() => {
        const handle = (res) => {
            if (res) {
                if (res.errorCode === undefined) {
                    setFormValues(res);
                }
                else props.history.push({ pathname: '/noaccess', state: res });
            } else {
                props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
            }
        }
        GetReminder(authCtx.auth.token, handle)
    }, [authCtx.auth.token, props.history])

    const onSubmit = (values, onSubmitProps) => {
        const handler = (res) => {
            if (res) {
                if (res.errorCode === undefined) {
                    setNotify({ isOpen: true, message: res.Description, type: res.ResultCode === 1 ? 'success' : "error" });
                } else props.history.push({ pathname: '/noaccess', state: res });
            } else props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
            onSubmitProps.setSubmitting(false);
        }
        SaveReminder(authCtx.auth.token, values, handler);
    }

    return (
        <PageLayout width="xs">
            <FormHeader text="Připomenutí akcí" />
            <PaperPad width='lg'>
                <Typography color="textSecondary">Posílání připomínek mailem. Skutečný čas připomenutí se může trochu lišit v závislosti na vytížení sítě a serverů.</Typography>
                {formValues &&
                    <Formik
                        initialValues={formValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize
                    >
                        {(formik) => {
                            const trn = formik.values.training;
                            const gam = formik.values.game;
                            return (
                                <Form>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Controls.Checkbox
                                                name="training"
                                                label="Trénink"
                                                color='secondary'
                                            />
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                        <Grid item xs={5} className={trn ? null : classes.hide}>
                                            <Controls.NumberInput
                                                name="trainingVal"
                                                label="Minut před"
                                                size='small'
                                                format="#### min"
                                            />
                                        </Grid>
                                        <Grid item xs={7} className={trn ? null : classes.hide}>
                                            <Controls.Checkbox
                                                name="trainingParticip"
                                                label="Jen při účasti"
                                                color='secondary'
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controls.Checkbox
                                                name="game"
                                                label="Zápas"
                                                color='secondary'
                                            />
                                        </Grid>
                                        <Grid item xs={6}></Grid>
                                        <Grid item xs={5} className={gam ? null : classes.hide}>
                                            <Controls.NumberInput
                                                name="gameVal"
                                                label="Minut před"
                                                size='small'
                                                format="#### min"
                                            />
                                        </Grid>
                                        <Grid item xs={7} className={gam ? null : classes.hide}>
                                            <Controls.Checkbox
                                                name="gameParticip"
                                                label="Jen při účasti"
                                                color='secondary'
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controls.Button fullWidth type="submit"
                                                disabled={/*!( formik.dirty &&  formik.isValid) ||*/ formik.isSubmitting}
                                                text="Uložit" />
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                        }}
                    </Formik>
                }
                <Notification notify={notify} setNotify={setNotify} enableClickAway={true} />
            </PaperPad>
        </PageLayout>
    );
}

export default UserSettings;