import {callWebApiAnonymous, callHandleResult } from "../../../services/WebApi";

export const GetDashboardData = (handler) => {
    const handleGetData = (res) => {
      callHandleResult(res, handler);
    }
    callWebApiAnonymous('Home', 'GET', null, handleGetData);
}

export const GetRecruitmentData = (handler) => {
    const handleGetData = (res) => {
      callHandleResult(res, handler);
    }
    callWebApiAnonymous('Recruitment', 'GET', null, handleGetData);
}  

export const GetTablesData = (handler) => {
  const handleGetData = (res) => {
    callHandleResult(res, handler);
  }
  callWebApiAnonymous('Tables', 'GET', null, handleGetData);
}  