import { AppBar, Avatar, Drawer, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import AuthContext from './AuthContext';
import { GetAuthModel, isInRole } from '../../services/AuthApi';
import logoImg from '../../../src/static/zlichov_logo2.png';

import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Cookies from 'js-cookie';
// icons
import MenuIcon from '@material-ui/icons/Menu';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';

import ScheduleIcon from '@material-ui/icons/Schedule';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PeopleIcon from '@material-ui/icons/People';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import TuneIcon from '@material-ui/icons/Tune';
import InfoIcon from '@material-ui/icons/Info';
import EuroIcon from '@material-ui/icons/Euro';
import SportsBasketballIcon from '@material-ui/icons/SportsBasketball';
import GridOnIcon from '@material-ui/icons/GridOn';

import { RouterSwitch } from './RouterSwitch';

const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex' },
  toolbar: theme.mixins.toolbar,
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: { display: 'none' }
  },
  title: { flexGrow: 1 },
  appBar: {
    [theme.breakpoints.up('md')]: { width: `calc(100% - ${drawerWidth}px)`, marginLeft: drawerWidth }
  },
  drawer: {
    [theme.breakpoints.up('md')]: { width: drawerWidth, flexShrink: 0 }
  },
  drawerPaper: {
    width: drawerWidth,
    '& .MuiListItemIcon-root': { minWidth: theme.spacing(4) }
  },
  drawerToolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: theme.shadows[4],
    justifyContent: "flex-start"
  },
  drawerList: { padding: theme.spacing(0) },
  logoAvatar: { marginRight: theme.spacing(1), height: "36px", width: "36px" },
  content: { flexGrow: 1, padding: theme.spacing(1.5), height: '100%' }
}));

function AppMenu(props) {
  const { window } = props;
  const setState = props.setState;
  const getState = props.getState;
  const authCtx = useContext(AuthContext);
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);

  const container = window !== undefined ? () => window().document.body : undefined;

  /* function GetEnvStyle(){
    const environment = process.env.REACT_APP_ENVIRONMENT;
    switch (environment){
      case 'Production': return null;
      case 'Development':  return {backgroundColor:'#5b307a'};
      case 'Test':  return {backgroundColor:'#307a30'}
      default: return null;
    }
  } */
  function MenuItems() {
    const handleRoute = (r) => {
      props.history.push(r);
    }

    return (
      <div>
        <Toolbar className={classes.drawerToolbar} /* style={GetEnvStyle()} */>
          <Typography variant="body1" color="inherit" noWrap>
            Basketbal
          </Typography>
        </Toolbar>
        { authCtx.auth.isAuthenticated ?
          <List className={classes.drawerList}>
            <ListItem button onClick={() => { handleRoute('/'); }}>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={"Nástěnka"} />
            </ListItem>
            {(isInRole(authCtx.auth, 'Player') || isInRole(authCtx.auth, 'Fan')) &&
              <ListItem button onClick={() => { handleRoute('/schedule'); }}>
                <ListItemIcon>
                  <ScheduleIcon />
                </ListItemIcon>
                <ListItemText primary={"Kalendář"} />
              </ListItem>}
            <ListItem button onClick={() => { handleRoute('/galery'); }}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary={"Galerie"} />
            </ListItem>
            <ListItem button onClick={() => { handleRoute('/cash'); }}>
              <ListItemIcon>
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText primary={"Platby"} />
            </ListItem>
            <ListItem button onClick={() => { handleRoute('/archive'); }}>
              <ListItemIcon>
                <InsertChartIcon />
              </ListItemIcon>
              <ListItemText primary={"Archiv"} />
            </ListItem>
            <ListItem button onClick={() => { handleRoute('/profile'); }}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={"Profil"} />
            </ListItem>
            {(isInRole(authCtx.auth, 'Player') || isInRole(authCtx.auth, 'Fan')) && 
            <ListItem button onClick={() => { handleRoute("/settings"); }}>
              <ListItemIcon>
                <TuneIcon />
              </ListItemIcon>
              <ListItemText primary={"Nastavení"} />
            </ListItem>}
            {isInRole(authCtx.auth, 'Stats') &&
            <ListItem button onClick={() => { handleRoute('/stats'); }}>
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary={"Statistiky"} />
            </ListItem>}
            {isInRole(authCtx.auth, 'Cashier') && 
            <ListItem button onClick={() => { handleRoute('/treasury'); }}>
              <ListItemIcon>
                <EuroIcon />
              </ListItemIcon>
              <ListItemText primary={"Pokladna"} />
            </ListItem>}
            {isInRole(authCtx.auth, 'Admin') && 
            <ListItem button onClick={() => { handleRoute('/appinfo'); }}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={"Administrace"} />
            </ListItem>}
          </List>
          :
          <List className={classes.drawerList}>
            <ListItem button onClick={() => { handleRoute('/'); }}>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={"Aktuality"} />
            </ListItem>
            <ListItem button onClick={() => { handleRoute('/recruit'); }}>
              <ListItemIcon>
                <SportsBasketballIcon />
              </ListItemIcon>
              <ListItemText primary={"Nábor"} />
            </ListItem>
            <ListItem button onClick={() => { handleRoute('/table'); }}>
              <ListItemIcon>
                <GridOnIcon />
              </ListItemIcon>
              <ListItemText primary={"Tabulky"} />
            </ListItem>
          </List>
        }
      </div>
    );
  }

  function getAuthIcon() {
    return (
      authCtx.auth.isAuthenticated ? <PowerSettingsNewIcon /> : <LockOutlinedIcon />
    )
  }

  function getThemeIcon() {
    return (getState.themeDark ? <BrightnessHighIcon /> : <Brightness4Icon />)
  }

  function handleLogout() {
    if (authCtx.auth.isAuthenticated) {
      authCtx.setAuth(GetAuthModel());
      Cookies.remove("authtoken");
      props.history.push('/');
    } else {
      props.history.push('/login');
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleChangeTheme = () => {
    let apitheme = !getState.themeDark
    setState({ themeDark: apitheme });
    Cookies.set("usersettings", apitheme ? true : false, { expires: 5000 });
  }

  const refreshPage = () => {
    props.history.go(0);
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} /* style={GetEnvStyle()} */>
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          <Avatar alt="logo" src={logoImg} variant="square" onClick={() => props.history.push('/')} className={classes.logoAvatar} />
          <Typography variant="body1" noWrap className={classes.title}>
            {(authCtx.auth.isAuthenticated ? <span>{authCtx.auth.firstName} {authCtx.auth.lastName}</span> : <span>Sokol Zlíchov</span>)}
          </Typography>

          {authCtx.auth.isAuthenticated && <IconButton onClick={refreshPage} color="inherit" edge="end">
            <AutorenewIcon />
          </IconButton>}

          <IconButton onClick={handleChangeTheme} color="inherit" edge="end">
            {getThemeIcon()}
          </IconButton>

          <IconButton onClick={handleLogout} color="inherit" edge="end">
            {getAuthIcon()}
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <div onClick={handleDrawerToggle}>
            <Drawer variant="temporary" anchor="left" open={mobileOpen} classes={{ paper: classes.drawerPaper }}
              container={container} onClose={handleDrawerToggle} ModalProps={{ keepMounted: true }}
            >
              <MenuItems />
            </Drawer>
          </div>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer anchor="left" variant="persistent" open={true} classes={{ paper: classes.drawerPaper }}>
            <MenuItems />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <RouterSwitch />
      </main>
    </div>
  );
}

export default withRouter(AppMenu);
