import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { Field } from 'formik';
import { FormControl } from '@material-ui/core';

export default function Autocomplete(props) {
    const { name, label, variant = "outlined", margin = "normal", size = 'medium',
     optionFieldName="title", options = [], ...other } = props;

    //const [value, setValue] = useState(options && options[0]);
    const [inputValue, setInputValue] = useState('');

    return (
        <Field name={name}>
            {({ form, field }) => {
                return (<>
{/*                     <div>{`value: ${value !== null ? `'${value}'` : 'null'}`}</div>
                    <div>{`inputValue: '${inputValue}'`}</div>
                    <br />
 */}                
                    <FormControl margin={margin} fullWidth>
                        <MuiAutocomplete
                            name={name}
                            size={size}
                            noOptionsText='žádná shoda'
                            fullWidth
                            options={options}
                            getOptionLabel={(option) => option[`${optionFieldName}`]}
                            getOptionSelected={(option) => option[`${optionFieldName}`]}
                            value={field.value} // selected by user
                            onChange={(event, newValue) => {
                                //setValue(newValue);
                                form.setFieldValue(field.name, newValue, true);
                            }}

                            inputValue={inputValue} // displayed in textbox
                            onInputChange={(event, newInputValue) => { 
                                setInputValue(newInputValue); 
                            }}
                            renderInput={
                                (params) => 
                                <TextField
                                    label={label}
                                    color='secondary'
                                    variant={variant}
                                    {...params} 
                                />
                            }
                            {...other}
                        />
                    </FormControl>
                </>)
            }
            }
        </Field>
    );
}