import React, { useContext, useEffect, useState } from 'react';
import PageLayout from '../Infrastructure/PageLayout';
import { FormHeader } from '../Infrastructure/PageElements';
import { FormControl, Grid, IconButton, InputLabel, makeStyles, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import AuthContext from '../../App/AuthContext';
import { GetStatFilter } from './AdminService';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DisplayValue, { DisplayAddress, DisplayList } from '../Partials/DisplayValue';
import Notification from '../../App/Notification';
import StatsDlg from './StatsDlg';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    backdrop: {
        zIndex: 2000,
    },
    event2: {
        color: theme.palette.color1.main,
    },
    table: {
        width: '100%',
        textAlign: 'left',
        border: '1px solid #808080',
        /* background: '#eff0f0', */
        borderCollapse: 'collapse',
        "& td": {
            padding: theme.spacing(0.5),
            border: '1px solid #808080',
            textAlign: 'center',
            "&.left": {
                textAlign: 'left',
            }
        },
        "& tr": {
            "&.light": {
                color: theme.palette.text.secondary,
            },
            "&.header": {
                color: theme.palette.secondary.main,
                background: theme.palette.secondary.light,
            },
            "&.footer": {
                color: theme.palette.secondary.main,
                background: theme.palette.secondary.light,
            }

        },
    },
}));
function Stats(props) {
    const classes = useStyles();
    const [apiFilter, setApiFilter] = useState(null);
    const [onRefreshChange, setOnRefreshChange] = useState(false);
    const authCtx = useContext(AuthContext);
    const closedNotify = { isOpen: false, message: '', type: '', title: '' };
    const [notify, setNotify] = useState(closedNotify);
    const [statsDlg, setStatsDlg] = useState(null);

    const refreshHandler = () => {
        setOnRefreshChange(!onRefreshChange);
    }

    const getParticipationIcon = (value) => {
        if (value === 1)
            return <CheckIcon className={classes.event2} />
        else return <ClearIcon color='error' />
    }
    const onTeamChange = (e) => {
        setApiFilter({ ...apiFilter, ...{ teamId: e.target.value, gameId: null } });
        refreshHandler();
    }
    const onGameChange = (e) => {
        setApiFilter({ ...apiFilter, gameId: e.target.value });
        refreshHandler();
    }

    const handleEdit = (stat) => {
        setStatsDlg(stat);
    }

    useEffect(() => {
        const handler = (res) => {
            if (res && res.errorCode === undefined) {
                setApiFilter(res);
            }
            else props.history.push({ pathname: '/noaccess', state: { errorCode: -1000, message: '' } });
        }
        GetStatFilter(authCtx.auth.token, apiFilter ? apiFilter.teamId : null, apiFilter ? apiFilter.gameId : null, handler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authCtx.auth.token, onRefreshChange]);

    return (
        apiFilter &&
        <PageLayout width="md">
            { apiFilter.gameLOV && (apiFilter.gameLOV.length > 0) 
            ? <FormHeader text="Statistiky zápasů" />
            : <FormHeader text="Není zadán žádný zápas" />}
            
            <Paper className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <InputLabel color='secondary' shrink>Tým</InputLabel>
                        <FormControl variant='outlined' margin='none' size='small' fullWidth>
                            <Select
                                name="teamId"
                                value={apiFilter.teamId}
                                onChange={onTeamChange}
                                color='secondary'
                            >
                                {apiFilter.teamLOV.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    { apiFilter.gameLOV && (apiFilter.gameLOV.length > 0) && 
                        <Grid item xs={12} sm={6}>
                            <InputLabel color='secondary' shrink>Zápas</InputLabel>
                            <FormControl variant='outlined' margin='none' size='small' fullWidth>
                                <Select
                                    name="gameId"
                                    value={apiFilter.gameId ?? ''}
                                    onChange={onGameChange}
                                    color='secondary'
                                >
                                    {apiFilter.gameLOV.map(item => (
                                        <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>}
                </Grid>
                { apiFilter.gameLOV && (apiFilter.gameLOV.length > 0) && 
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography>
                                {apiFilter.game.teamAName} - {apiFilter.game.teamBName}&nbsp;{apiFilter.game.scoreA} : {apiFilter.game.scoreB}
                                &nbsp;(
                                <DisplayList list={apiFilter.game.partials} itemFn={(item) => { return item.scoreA + ":" + item.scoreB; }} />
                                )
                            </Typography>
                            <Typography>
                                <DisplayValue type='date' value={apiFilter.game.gameDate} /> <DisplayAddress address={apiFilter.game.address} city={apiFilter.game.city} />
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Paper>
            { apiFilter.gameLOV && (apiFilter.gameLOV.length > 0) && <>
                <Paper className={classes.root}>
                <table className={classes.table}>
                    <thead>
                        <tr className='header'>
                            <td>Č</td>
                            <td className='left'>Jméno</td>
                            <td>Účast</td>
                            <td>Body</td>
                            <td>Šestky</td>
                            <td>Trojky</td>
                            <td>Fauly</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            apiFilter.stats.map(s => (
                                <tr key={s.id}>
                                    <td>{s.jerseyNumber}</td>
                                    <td className='left'>{s.playerName}</td>
                                    <td>
                                        <IconButton size='small' component="span" onClick={() => { handleEdit(s); }}>
                                            {getParticipationIcon(s.participationCount)}
                                        </IconButton>
                                    </td>
                                    <td>{s.points}</td>
                                    <td>{s.participationCount > 0 ? s.freeThrowsMade + " / " + s.freeThrows : ""}</td>
                                    <td>{s.threePointsMade}</td>
                                    <td>{s.fouls}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </Paper>
                <StatsDlg
                    name="statdlg"
                    stats={statsDlg}
                    eventId={apiFilter.gameId}
                    setStats={setStatsDlg}
                    refreshHandler={refreshHandler}
                />
                <Notification notify={notify} setNotify={setNotify} enableClickAway={true} />
                </>
            }
        </PageLayout>
    );
}

export default Stats;
